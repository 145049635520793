<template>
  <div id="layout" class="layout">
    <PanelHeader></PanelHeader>
    <PanelNav></PanelNav>
    <el-container v-if="this.$route.path=='/cockpit/electricity'">
      <el-main class="electricity">
        <router-view />
      </el-main>
    </el-container>
    <el-container v-else class="main">
      <el-aside width="231px">
        <PanelMenu> </PanelMenu>
      </el-aside>
      <el-main class="rightMain">
        <router-view />
      </el-main>
    </el-container>
    <Footer></Footer>
  </div>
</template>
<script>
import  PanelMenu from "./panelMenu.vue";
import PanelHeader from "./header.vue";
import Footer from "./footer.vue";
import PanelNav from "./panelNav.vue";
export default {
  name: 'Layout',
  components: { PanelHeader, PanelMenu, Footer,PanelNav },
};
</script>
<style scoped>
#layout {
  width: 100%;
  /* min-width: 1920px; */
  height: 100%;
  /* height: calc(100vh - 150px ); */
  background: url("../assets/images/cockpit/background.jpg") ;
  background-size: cover;
  position: relative;

}

.main {
  /* min-width: 1920px; */
  width: 100%;
  /* height: 903px; */
  height: calc(100vh - 151px );
  margin-top: 18px;
  padding-left: 20px;
  box-sizing: border-box;
}
.electricity{
  height: calc(100vh - 133px);
}
.rightMain{
  margin-left: 18px;
}

.el-aside {
  min-height: 781px;
  background-image: url("../assets/images/u1.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  margin-bottom: 20px;
}

.el-main {
  background-image: url("../assets/images/rbg.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  padding: 0;
  /* margin-left: 20px; */
}
::v-deep .el-aside::-webkit-scrollbar {
  width: 10px;
  /* 设置滚动条高度 */
  background-color: #09234e;
  /* 设置滚动条背景色 */
}

::v-deep .el-aside::-webkit-scrollbar-thumb {
  background-color: #003d74;
  /* 设置滚动条滑块颜色 */
  border-radius: 5px;
  /* 设置滚动条滑块的圆角 */
}
::v-deep .el-main::-webkit-scrollbar {
  width: 10px;
  /* 设置滚动条高度 */
  background-color: #09234e;
  /* 设置滚动条背景色 */
}

::v-deep .el-main::-webkit-scrollbar-thumb {
  background-color: #003d74;
  /* 设置滚动条滑块颜色 */
  border-radius: 5px;
  /* 设置滚动条滑块的圆角 */
}
</style>
