import { getRequest, postRequest,urlTitle } from "@/utils/request";
import qs from "qs";
window.qs = qs;
// 消息管理
export const messageApi = {
  // 消息列表
  queryMessage: (params) => {
    return getRequest(`${urlTitle}/message/page/list`, params);
  },
  // 修改消息
  updateMessageStatus: (id,messageFlag) => {
    return postRequest(`${urlTitle}/message/${id}/${messageFlag}`);
  },
};
