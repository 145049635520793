<template>
  <div id="panel">
    <myHeader></myHeader>
    <el-container class="main">
      <el-aside :width="aside?'300px':'20px'" class="ListL">
        <AsideBar v-if="aside">
        </AsideBar>
      </el-aside>
      <div class="searchL " :class="aside?'':'l20'" @click="opcityAside">
        <!-- <h1>1111111</h1> -->
        <img src="../../assets/images/asideBar/liftL.png" alt="">
      </div>
      <el-main class="contR">
        <router-view />
      </el-main>
    </el-container>
    <Footer></Footer>
  </div>
</template>
<script>
import AsideBar from "../../components/asideBar.vue";
import myHeader from "../../components/homeHeader.vue";
import Footer from "../../components/footer.vue";
export default {
  components: { myHeader, AsideBar, Footer },
  data() {
    return {
      property: 'value',
      aside:true,
    };
  },
  methods:{
    opcityAside(){
      // this.asideWidth='30px'
      this.aside=!this.aside

    }
  }
}

</script>
<style scoped>
#panel {
  width: 100%;
  height: 100%;
  background: url(../../assets/images/panel_bj.jpg);
  background-size: cover;
  position: relative;
}

.main {
  height: calc(100vh - 130px);
  
}

.ListL{
  position: relative;
  font-size: 16px;
}
.searchL{
  font-size: 16px;
  position: absolute;
  top: 49%;
  left: 300px;
  width: 15px;
  height: 60px;
  cursor: pointer;
}

.l20{
  left: 20px;
}





.el-aside {
  background-image: url('../../assets/images/u1.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.el-main {
  background-image: url('../../assets/images/rbg.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}
::v-deep .el-aside::-webkit-scrollbar {
  width: 10px;
  /* 设置滚动条高度 */
  background-color: #09234E;
  /* 设置滚动条背景色 */
}

::v-deep .el-aside::-webkit-scrollbar-thumb {
  background-color: #003d74;
  /* 设置滚动条滑块颜色 */
  border-radius: 5px;
  /* 设置滚动条滑块的圆角 */
}
::v-deep .el-main::-webkit-scrollbar {
  width: 10px;
  /* 设置滚动条高度 */
  background-color: #09234E;
  /* 设置滚动条背景色 */
}

::v-deep .el-main::-webkit-scrollbar-thumb {
  background-color: #003d74;
  /* 设置滚动条滑块颜色 */
  border-radius: 5px;
  /* 设置滚动条滑块的圆角 */
}
</style>