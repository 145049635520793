import Vue from "vue";
import store from "../store";
import VueRouter from "vue-router";
import login from "../views/login/index.vue";
import panel from "../views/panel/panel.vue";
import Layout from "../components/layout.vue";
import ControlPanel from "../components/control-panel.vue";
import dailyWork from "../views/dailyWork/dailyWork.vue";
import assessment from "../views/assessment/assessment.vue";
import equipment from "../views/equipment/equipment.vue";
import security from "../views/security/security.vue";
import energy from "../views/energy/energy.vue";
import statistics from "../views/statistics/statistics.vue";
import knowledge from "../views/knowledge/knowledge.vue";
import planAdministration from "../views/planAdministration/planAdministration.vue";
import operationManagement from "../views/operationManagement/operationManagement.vue";
import emissionsCarbon from "../views/emissionsCarbon/emissionsCarbon.vue";
import eios from "../views/eios/eios.vue";
// import store from "../store/index.js";
Vue.use(VueRouter);
let parentComponent = store.state.componentStatus === 0 ? Layout : ControlPanel;
const routes = [
    // 登录
    {
        path: "/login",
        name: "login",
        component: login,
    },
    {
        path: "/",
        name: "home",
        component: () => import("../views/home/index.vue"),
    },
    {
        path: "/cockpitGas",
        name: "cockpit-gas",
        component: () => import("../views/cockpit-gas/index.vue"),
    },
    {
        path: "/cockpitWarm",
        name: "cockpit-warm",
        component: () => import("../views/cockpit-warm/index.vue"),
    },
    {
        path: "/cockpitWater",
        name: "cockpit-water",
        component: () => import("../views/cockpit-water/index.vue"),
    },
    {
        path: "/cockpit",
        name: "cockpit",
        component: Layout,
        children: [
            //驾驶舱-网首页
            {
                path: "electricity",
                name: "electricity",
                component: () => import("../views/cockpit-electricity/index.vue"),
            },
        ],
    },
    {
        path: "/energy-storage",
        name: "energy-storage",
        component: () => import("../views/electricity-lotus/storage.vue")
    },

    // {
    //   path: "/cockpit",
    //   name: "cockpit",
    //   component: () => import("../views/cockpit-electricity/index.vue"),
    // },
    {
        path: "/electricityHome",
        name: "electricity-home",
        component: () => import("../views/electricity/index.vue"),
    },
    {
        path: "/electricitySource",
        name: "electricity-source",
        component: () => import("../views/electricity-source/index.vue"),
    },
    {
        path: "/electricityNetwork",
        name: "electricity-network",
        component: () => import("../views/electricity-network/index.vue"),
    }, {
        path: "/electricityLotus",
        name: "electricity-lotus",
        component: () => import("../views/electricity-lotus/index.vue"),
    }, {
        path: "/lotusDetail",
        name: "lotusDetail",
        component: () => import("../views/electricity-lotus/lotus.vue"),
    }, {
        path: "/electricityStore",
        name: "electricity-store",
        component: () => import("../views/electricity-store/index.vue"),
    },
    //综合监控1
    {
        path: "/monitoringLeft",
        name: "monitoringLeft",
        component: () => import("../views/monitoring/monitoringLeft.vue"),
    },
    //综合监控2
    {
        path: "/monitoringLeft2",
        name: "monitoringLeft2",
        component: () => import("../views/monitoring/monitoringLeft2.vue"),
    },
    //综合明细
    {
        path: "/monitoringDetail",
        name: "monitoringDetail",
        component: () => import("../views/monitoring/monitoringDetail.vue"),
    },
    //综合明细---复制
    {
        path: "/source",
        name: "source",
        component: () => import("../views/monitoring/source.vue"),
    },
    //Eios控制面板
    {
        path: "/eios",
        name: "eios",
        component: eios,
        children: [
            //场站管理
            {
                path: "energyStation",
                name: "energyStation",
                component: () => import("../views/eios/energy-station.vue"),
            },
            //巡检单管理
            {
                path: "inspectionForm",
                name: "inspectionForm",
                component: () => import("../views/eios/inspection-form.vue"),
            },
            //巡检计划管理
            {
                path: "pollingPlan",
                name: "pollingPlan",
                component: () => import("../views/eios/pollingPlan.vue"),
            },
            //巡检项管理
            {
                path: "inspectionItem",
                name: "inspectionItem",
                component: () => import("../views/eios/inspection-item.vue"),
            },
            //试验项管理
            {
                path: "testItem",
                name: "testItem",
                component: () => import("../views/eios/test-item.vue"),
            },
            //设备管理
            {
                path: "device",
                name: "device",
                component: () => import("../views/eios/device.vue"),
            },
            //计量点管理
            {
                path: "metering",
                name: "metering",
                component: () => import("../views/eios/metering.vue"),
            },
            //监测回路
            {
                path: "monitoringLoop",
                name: "monitoringLoop",
                component: () => import("../views/eios/monitoringLoop.vue"),
            },
            //字典管理
            {
                path: "dictionary",
                name: "Dictionary",
                component: () => import("../views/eios/dictionary.vue"),
            },
            //字典子级
            {
                path: "dictionarySun",
                name: "DictionarySun",
                component: () => import("../views/eios/dictionarySun.vue"),
            },
            //单位管理
            {
                path: "companyInfo",
                name: "CompanyInfo",
                component: () => import("../views/eios/companyInfo.vue"),
            },
            //回路管理
            {
                path: "adminLoop",
                name: "AdminLoop",
                component: () => import("../views/eios/adminLoop.vue"),
            },
            //回路管理
            {
                path: "user",
                name: "user",
                component: () => import("../views/eios/user.vue"),
            },
            //角色管理
            {
                path: "roleAdmin",
                name: "RoleAdmin",
                component: () => import("../views/eios/roleAdmin.vue"),
            },
            //角色管理-角色绑定
            {
                path: "roleAdminSun",
                name: "RoleAdminSun",
                component: () => import("../views/eios/roleAdminSun.vue"),
            },
            //菜单管理
            {
                path: "menuAdministration",
                name: "MenuAdministration",
                component: () => import("../views/eios/menuAdministration.vue"),
            },
            //电价类别收费管理
            {
                path: "priceElectricity",
                name: "PriceElectricity",
                component: () => import("../views/eios/priceElectricity.vue"),
            },
            //空调密码管理
            {
                path: "airConditioning",
                name: "AirConditioning",
                component: () => import("../views/eios/airConditioning.vue"),
            },
            //采集设备信息管理
            {
                path: "collection",
                name: "Collection",
                component: () => import("../views/eios/collection.vue"),
            },
            //告警管理
            {
                path: "allAlarm",
                name: "AllAlarm",
                component: () => import("../views/eios/allAlarm.vue"),
            },
        ],
    },
    // 日常办公
    {
        path: "/dailyWork",
        name: "DailyWork",
        component: dailyWork,
        // redirect:'/feedback',
        children: [
            //意见反馈
            {
                path: "feedback",
                name: "Feedback",
                component: () => import("../views/dailyWork/feedback.vue"),
            },
            //文档管理
            {
                path: "document",
                name: "Document",
                component: () => import("../views/dailyWork/document.vue"),
            },
            //员工通讯录
            {
                path: "staffAddressBook",
                name: "StaffAddressBook",
                component: () => import("../views/dailyWork/staffAddressBook.vue"),
            },
            //员工通讯录
            {
                path: "customerAddressBook",
                name: "CustomerAddressBook",
                component: () => import("../views/dailyWork/customerAddressBook.vue"),
            },
            //公告管理
            {
                path: "notice",
                name: "Notice",
                component: () => import("../views/dailyWork/notice.vue"),
            },
            // //字典管理
            // {
            //   path: "dictionary",
            //   name: "Dictionary",
            //   component: () => import("../views/dailyWork/dictionary.vue"),
            // },
            // //字典数据
            // {
            //   path: "dictionarySun",
            //   name: "DictionarySun",
            //   component: () => import("../views/dailyWork/dictionarySun.vue"),
            // },
            //消息管理
            {
                path: "message",
                name: "Message",
                component: () => import("../views/dailyWork/message.vue"),
            },
        ],
    },
    // 事件记录
    {
        path: "/event",
        name: "event",
        component: {
            render(c) {
                // 根据 store.state.componentStatus 的值动态选择要渲染的组件
                if (store.state.componentStatus == 0) {
                    return c(ControlPanel);
                } else if (store.state.componentStatus === 1) {
                    return c(Layout);
                } else {
                    return c(Layout);

                }
            },
        },
        // component:Layout,
        children: [
            //操作日志
            {
                path: "operation",
                name: "operation",
                component: () => import("../views/event-record/operation-log.vue"),
            },
            //平台运行日志
            {
                path: "platform",
                name: "platform",
                component: () => import("../views/event-record/platform-run.vue"),
            },
            //遥信事件
            {
                path: "remote",
                name: "remote",
                component: () => import("../views/event-record/remote-signaling.vue"),
            },
            //告警事件
            {
                path: "alarm",
                name: "Alarm",
                component: () => import("../views/event-record/alarm.vue"),
            },
            //告警分析
            {
                path: "alarmAnalysis",
                name: "alarmAnalysis",
                component: () => import("../views/event-record/alarm-analysis.vue"),
            },
            //短信日志
            {
                path: "sms",
                name: "sms",
                component: () => import("../views/event-record/sms-log.vue"),
            },
            //视在功率
            {
                path: "apparent",
                name: "apparent",
                component: () => import("../views/event-record/apparent-power.vue"),
            },
            //电流
            {
                path: "current",
                name: "current",
                component: () => import("../views/event-record/current.vue"),
            },
            //电压
            {
                path: "voltage",
                name: "voltage",
                component: () => import("../views/event-record/voltage.vue"),
            },
            //功率因数
            {
                path: "factor",
                name: "factor",
                component: () => import("../views/event-record/power-factor.vue"),
            },
            //变压器温度
            {
                path: "heat",
                name: "heat",
                component: () => import("../views/event-record/heat.vue"),
            },
            //变压器噪声
            {
                path: "noise",
                name: "noise",
                component: () => import("../views/event-record/noise.vue"),
            },
            //变压器负载
            {
                path: "transformerLoad",
                name: "transformerLoad",
                component: () => import("../views/event-record/load.vue"),
            },
            //电压频率
            {
                path: "frequency",
                name: "frequency",
                component: () => import("../views/event-record/frequency.vue"),
            },
            //环境温度
            {
                path: "temperature",
                name: "temperature",
                component: () => import("../views/event-record/temperature.vue"),
            },
            //环境湿度
            {
                path: "humidity",
                name: "humidity",
                component: () => import("../views/event-record/humidity.vue"),
            },
            //局放
            {
                path: "discharge",
                name: "discharge",
                component: () => import("../views/event-record/partial-discharge.vue"),
            },
            //高压母线
            {
                path: "highBusbar",
                name: "highBusbar",
                component: () => import("../views/event-record/high-busbar.vue"),
            },
            //低压母线
            {
                path: "lowBusbar",
                name: "lowBusbar",
                component: () => import("../views/event-record/low-busbar.vue"),
            },
        ],
    },
    // 用电分析
    {
        path: "/analysis",
        name: "Analysis",
        component: {
            render(c) {
                // 根据 store.state.componentStatus 的值动态选择要渲染的组件
                if (store.state.componentStatus == 0) {
                    return c(ControlPanel);
                } else if (store.state.componentStatus === 1) {
                    return c(Layout);
                } else {
                    return c(Layout);

                }
            },
        },
        children: [
            //用能报表
            {
                path: "energy",
                name: "energy",
                component: () =>
                    import("../views/electricity-analysis/energy-report.vue"),
            },
            //同比分析
            {
                path: "year",
                name: "year",
                component: () =>
                    import("../views/electricity-analysis/year-analysis.vue"),
            },
            //环比分析
            {
                path: "month",
                name: "month",
                component: () =>
                    import("../views/electricity-analysis/month-analysis.vue"),
            },
            //电能集抄
            {
                path: "collect",
                name: "collect",
                component: () =>
                    import("../views/electricity-analysis/electricity-collect.vue"),
            },
            // 分时段用电
            {
                path: "period",
                name: "period",
                component: () =>
                    import("../views/electricity-analysis/day-parting.vue"),
            },
            // 最大需量报表
            {
                path: "demand",
                name: "demand",
                component: () =>
                    import("../views/electricity-analysis/demand-report.vue"),
            },
            // 用电负荷
            {
                path: "load",
                name: "load",
                component: () => import("../views/electricity-analysis/load.vue"),
            },
            // 实时需量
            {
                path: "now",
                name: "now",
                component: () => import("../views/electricity-analysis/now-demand.vue"),
            },
            // 用能及电费报表
            {
                path: "cost",
                name: "cost",
                component: () =>
                    import("../views/electricity-analysis/cost-report.vue"),
            },
            // 整点集抄
            {
                path: "integral",
                name: "integral",
                component: () =>
                    import("../views/electricity-analysis/integral-collect.vue"),
            },
        ],
    },
    // 电力监测
    {
        path: "/monitor",
        name: "monitor",
        component: {
            render(c) {
                // 根据 store.state.componentStatus 的值动态选择要渲染的组件
                if (store.state.componentStatus == 0) {
                    return c(ControlPanel);
                } else if (store.state.componentStatus === 1) {
                    return c(Layout);
                } else {
                    return c(Layout);

                }
            },
        },
        children: [
            //配电图
            {
                path: "distribution",
                name: "distribution",
                component: () =>
                    import("../views/electricity-monitor/distribution.vue"),
            },
            //高压侧
            {
                path: "main",
                name: "main",
                component: () => import("../views/electricity-monitor/main-line.vue"),
            },
            //馈线
            {
                path: "feeder",
                name: "feeder",
                component: () => import("../views/electricity-monitor/feeder-line.vue"),
            },
            //低压侧
            {
                path: "low",
                name: "low",
                component: () => import("../views/electricity-monitor/low-voltage.vue"),
            },
            // 电力极值报表
            {
                path: "extreme",
                name: "extreme",
                component: () =>
                    import("../views/electricity-monitor/extreme-report.vue"),
            },
            // 电力运行日报
            {
                path: "running",
                name: "running",
                component: () =>
                    import("../views/electricity-monitor/running-report.vue"),
            },
            // 平均功率因数
            {
                path: "power",
                name: "power",
                component: () => import("../views/electricity-monitor/power.vue"),
            },
            // 变压器
            {
                path: "transformer",
                name: "transformer",
                component: () => import("../views/electricity-monitor/transformer.vue"),
            },
            // 直流屏
            {
                path: "screen",
                name: "screen",
                component: () => import("../views/electricity-monitor/dc-screen.vue"),
            },
        ],
    },
    // 电能质量
    {
        path: "/quality",
        name: "quality",
        component: {
            render(c) {
                // 根据 store.state.componentStatus 的值动态选择要渲染的组件
                if (store.state.componentStatus == 0) {
                    return c(ControlPanel);
                } else if (store.state.componentStatus === 1) {
                    return c(Layout);
                } else {
                    return c(Layout);
                }
            },
        },
        children: [
            //谐波监测
            {
                path: "wave",
                name: "wave",
                component: () =>
                    import("../views/electricity-quality/wave-monitor.vue"),
            },
        ],
    },
    // 运行环境
    {
        path: "/run",
        name: "run",
        component: {
            render(c) {
                // 根据 store.state.componentStatus 的值动态选择要渲染的组件
                if (store.state.componentStatus == 0) {
                    return c(ControlPanel);
                } else if (store.state.componentStatus === 1) {
                    return c(Layout);
                } else {
                    return c(Layout);

                }
            },
        },
        children: [
            //环境监测
            {
                path: "environment",
                name: "environment",
                component: () => import("../views/operate-environment/monitor.vue"),
            },
            //实时监控
            {
                path: "nowMonitor",
                name: "nowMonitor",
                component: () => import("../views/operate-environment/now-monitor.vue"),
            },
            //视频识别告警
            {
                path: "playback",
                name: "playback",
                component: () =>
                    import("../views/operate-environment/monitor-playback.vue"),
            },
            //视频设备状态
            {
                path: "videoDevice",
                name: "video-device",
                component: () =>
                    import("../views/operate-environment/video-device.vue"),
            },
            //仪表通讯状态
            {
                path: "instrument",
                name: "instrument",
                component: () => import("../views/operate-environment/instrument.vue"),
            },
            // 网关通信状态
            {
                path: "gateway",
                name: "gateway",
                component: () => import("../views/operate-environment/gateway.vue"),
            },
            // 查看视频
            {
                path: "video",
                name: "video",
                component: () => import("../views/operate-environment/view-video.vue"),
            },
        ],
    },
    // 安全用电
    {
        path: "/safety",
        name: "safety",
        component: {
            render(c) {
                // 根据 store.state.componentStatus 的值动态选择要渲染的组件
                if (store.state.componentStatus == 0) {
                    return c(ControlPanel);
                } else if (store.state.componentStatus === 1) {
                    return c(Layout);
                } else {
                    return c(Layout);
                }
            },
        },
        children: [
            //安防联控
            {
                path: "securityControl",
                name: "securityControl",
                component: () => import("../views/electricity-safety/light-device.vue"),
            },
            //空调联动装置
            {
                path: "air",
                name: "air",
                component: () => import("../views/electricity-safety/air-device.vue"),
            },
            //红外探测器
            {
                path: "detector",
                name: "detector",
                component: () =>
                    import("../views/electricity-safety/infrared-detector.vue"),
            },
            //智能门锁
            {
                path: "lock",
                name: "lock",
                component: () => import("../views/electricity-safety/smart-lock.vue"),
            },
            //声光报警器
            {
                path: "audible",
                name: "audible",
                component: () =>
                    import("../views/electricity-safety/audible-alarm.vue"),
            },
            // 无源无线温度传感器
            {
                path: "temperatureSensor",
                name: "temperatureSensor",
                component: () => import("../views/electricity-safety/temperature.vue"),
            },
            // 噪声传感器
            {
                path: "noiseSensor",
                name: "noiseSensor",
                component: () => import("../views/electricity-safety/noise.vue"),
            },
            // 变压器温控
            {
                path: "transformerControl",
                name: "transformerControl",
                component: () => import("../views/electricity-safety/transformer.vue"),
            },
            // 特高频局放
            {
                path: "partial",
                name: "partial",
                component: () =>
                    import("../views/electricity-safety/partial-discharge.vue"),
            },
            // 变压器声纹
            {
                path: "vocal",
                name: "vocal",
                component: () => import("../views/electricity-safety/vocal-print.vue"),
            },
            // 烟雾传感器
            {
                path: "fireSafety",
                name: "fireSafety",
                component: () => import("../views/electricity-safety/smoke-sensor.vue"),
            },
            // 温湿度传感器
            {
                path: "humiditySensor",
                name: "humiditySensor",
                component: () => import("../views/electricity-safety/humidity.vue"),
            },
            // SF6+O3C传感器
            {
                path: "SF6Sensor",
                name: "SF6Sensor",
                component: () => import("../views/electricity-safety/sf6-sensor.vue"),
            },
            // 水浸
            {
                path: "water",
                name: "water",
                component: () =>
                    import("../views/electricity-safety/water-immersion.vue"),
            },
        ],
    },
    //考核管理
    {
        path: "/assessment",
        name: "Assessment ",
        component: assessment,
        children: [
            {
                path: "management",
                name: "Management",
                component: () => import("../views/assessment/management.vue"),
            },
        ],
    },
    //设备管理
    {
        path: "/equipment",
        name: "equipment ",
        component: equipment,
        children: [
            {
                path: "administration",
                name: "Administration",
                component: () => import("../views/equipment/administration.vue"),
            },
        ],
    },
    //安全管理
    {
        path: "/security",
        name: "security ",
        component: {
            render(c) {
                // 根据 store.state.componentStatus 的值动态选择要渲染的组件
                if (store.state.componentStatus == 0) {
                    return c(ControlPanel);
                } else if (store.state.componentStatus === 1) {
                    return c(Layout);
                } else {
                    return c(Layout);
                }
            },
        },
        children: [
            //安防联控-灯光联动装置
            {
                path: "control",
                name: "Control",
                component: () => import("../views/security/control.vue"),
            },
            //灯光联动装置
            {
                path: "conditioning",
                name: "Conditioning",
                component: () => import("../views/security/conditioning.vue"),
            },
            //红外探测器
            {
                path: "infrared",
                name: "Infrared",
                component: () => import("../views/security/infrared.vue"),
            },
            //智能门锁
            {
                path: "doorLock",
                name: "DoorLock",
                component: () => import("../views/security/doorLock.vue"),
            },
            //声光报警器
            {
                path: "acoustoOptic",
                name: "AcoustoOptic",
                component: () => import("../views/security/acoustoOptic.vue"),
            },
            //状态评价--无源无线温度传感器
            {
                path: "temperature",
                name: "Temperature",
                component: () => import("../views/security/temperature.vue"),
            },
            //噪声传感器
            {
                path: "noise",
                name: "Noise",
                component: () => import("../views/security/noise.vue"),
            },
            //变压器温控
            {
                path: "transformer",
                name: "Transformer",
                component: () => import("../views/security/transformer.vue"),
            },
            //特高频局放
            {
                path: "frequency",
                name: "Frequency",
                component: () => import("../views/security/frequency.vue"),
            },
            //变压器声纹
            {
                path: "voicePrint",
                name: "VoicePrint",
                component: () => import("../views/security/voicePrint.vue"),
            },
            //环境监测-烟雾传感器
            {
                path: "smoke",
                name: "Smoke",
                component: () => import("../views/security/smoke.vue"),
            },
            //温湿度传感器
            {
                path: "humidity",
                name: "Humidity",
                component: () => import("../views/security/humidity.vue"),
            },
            //sf6o3
            {
                path: "sf6o3",
                name: "sf6o3",
                component: () => import("../views/security/sf6o3.vue"),
            },
            //水浸
            {
                path: "water",
                name: "Water",
                component: () => import("../views/security/water.vue"),
            },
        ],
    },
    //能源管理
    {
        path: "/energy",
        name: "Energy ",
        component: energy,
        children: [
            //能源管理
            {
                path: "equipment",
                name: "Equipment",
                component: () => import("../views/energy/equipment.vue"),
            },
            //变压器
            {
                path: "energyTransformer",
                name: "energyTransformer",
                component: () => import("../views/energy/transformer.vue"),
            },
            //特高频
            {
                path: "highFrequency",
                name: "HighFrequency",
                component: () => import("../views/energy/highFrequency.vue"),
            },
            //局放
            {
                path: "partial",
                name: "Partial",
                component: () => import("../views/energy/partial.vue"),
            },
            //碳排分析
            {
                path: "emissionsCarbonA",
                name: "EmissionsCarbonA",
                component: () => import("../views/energy/emissionsCarbon.vue"),
            },
            //能源运营
            {
                path: "operations",
                name: "Operations",
                component: () => import("../views/energy/operations.vue"),
            },
        ],
    },
    //统计分析
    {
        path: "/statistics",
        name: "Statistics ",
        component: statistics,
        children: [
            //统计分析
            {
                path: "analysis",
                name: "StatisticsAnalysis",
                component: () => import("../views/statistics/analysis.vue"),
            },
            //用能报表
            {
                path: "energyReport",
                name: "energyReport",
                component: () => import("../views/statistics/energyReport.vue"),
            },
            //电能集抄
            {
                path: "collect",
                name: "collect",
                component: () => import("../views/statistics/collect.vue"),
            },
            // 分时段用电
            {
                path: "dayParting",
                name: "dayParting",
                component: () =>
                    import("../views/statistics/dayParting.vue"),
            },
            //统计分析/同比分析
            {
                path: "yearAnalysis",
                name: "yearAnalysis",
                component: () =>
                    import("../views/statistics/yearAnalysis.vue"),
            },
            //环比分析
            {
                path: "monthAnalysis",
                name: "monthAnalysis",
                component: () =>
                    import("../views/statistics/monthAnalysis.vue"),
            },
            //电力运行数据
            {
                path: "powerRun",
                name: "powerRun",
                component: () => import("../views/statistics/powerRun.vue"),
            },
            //设备统计
            {
                path: "equipmentStatistics",
                name: "equipmentStatistics",
                component: () => import("../views/statistics/equipmentStatistics.vue"),
            },
            // 国网计量统计
            {
                path: "country",
                name: "country",
                component: () =>
                    import("../views/statistics/country.vue"),
            },
        ],
    },
    //知识库
    {
        path: "/knowledge",
        name: "Knowledge ",
        component: knowledge,
        children: [
            {
                path: "library",
                name: "Library",
                component: () => import("../views/knowledge/library.vue"),
            },
        ],
    },
    //计划管理
    {
        path: "/planAdministration",
        name: "PlanAdministration ",
        component: planAdministration,
        children: [
            //巡视计划
            {
                path: "patrol",
                name: "Patrol",
                component: () => import("../views/planAdministration/patrol.vue"),
            },
            //检修计划
            {
                path: "overhaul",
                name: "Overhaul",
                component: () => import("../views/planAdministration/overhaul.vue"),
            },
            //培训计划
            {
                path: "train",
                name: "Train",
                component: () => import("../views/planAdministration/train.vue"),
            },
            //采购计划
            {
                path: "purchase",
                name: "Purchase",
                component: () => import("../views/planAdministration/purchase.vue"),
            },
            //巡检计划
            {
                path: "inspectionPlan",
                name: "inspectionPlan",
                component: () => import("../views/planAdministration/inspection.vue"),
            },
            //试验计划
            {
                path: "testPlan",
                name: "testPlan",
                component: () => import("../views/planAdministration/test.vue"),
            },
            //停电计划
            {
                path: "blackout",
                name: "Blackout",
                component: () => import("../views/planAdministration/blackout.vue"),
            },
        ],
    },
    //运维管理
    {
        path: "/operationManagement",
        name: "OperationManagement",
        component: operationManagement,
        children: [
            // // 任务管理
            // {
            //   path: "operationTask",
            //   name: "OperationTask",
            //   component: () =>
            //     import("../views/operationManagement/operationTask.vue"),
            // },
            //设备在线状态
            {
                path: "onLine",
                name: "onLine",
                component: () =>
                    import("../views/operationManagement/onLine.vue"),
            },
            //巡检任务
            {
                path: "inspectionTask",
                name: "inspectionTask",
                component: () =>
                    import("../views/operationManagement/inspection-task.vue"),
            },
            //维修记录
            {
                path: "operationService",
                name: "OperationService",
                component: () =>
                    import("../views/operationManagement/operationService.vue"),
            },
            //抢修记录
            {
                path: "operationEmergency",
                name: "OperationEmergency",
                component: () =>
                    import("../views/operationManagement/operationEmergency.vue"),
            },
            //试验任务
            {
                path: "testTask",
                name: "testTask",
                component: () => import("../views/operationManagement/test-task.vue"),
            },
            //消警记录
            {
                path: "operationWarning",
                name: "OperationWarning",
                component: () =>
                    import("../views/operationManagement/operationWarning.vue"),
            },
            //抢修任务
            {
                path: "rushRepair",
                name: "RushRepair",
                component: () => import("../views/operationManagement/rush-repair.vue"),
            },
            //维修任务
            {
                path: "taskList",
                name: "TaskList",
                component: () => import("../views/operationManagement/taskList.vue"),
            },
        ],
    },
    //碳排分析
    {
        path: "/emissionsCarbon",
        name: "EmissionsCarbon",
        component: {
            render(c) {
                // 根据 store.state.componentStatus 的值动态选择要渲染的组件
                if (store.state.componentStatus == 0) {
                    return c(ControlPanel);
                } else if (store.state.componentStatus === 1) {
                    return c(Layout);
                } else {
                    return c(Layout);
                }
            },
        },
        children: [
            //实时碳排
            {
                path: "realTime",
                name: "RealTime",
                component: () => import("../views/emissionsCarbon/realTime.vue"),
            },
            //碳排统计
            {
                path: "statistics",
                name: "Statistics",
                component: () => import("../views/emissionsCarbon/statistics.vue"),
            },
            //累计碳排
            {
                path: "cumulative",
                name: "Cumulative",
                component: () => import("../views/emissionsCarbon/cumulative.vue"),
            },
            //同比分析
            {
                path: "yearOnYear",
                name: "YearOnYear",
                component: () => import("../views/emissionsCarbon/yearOnYear.vue"),
            },
            //同比分析
            {
                path: "monthOnMonth",
                name: "MonthOnMonth",
                component: () => import("../views/emissionsCarbon/monthOnMonth.vue"),
            },
        ],
    },
];

const router = new VueRouter({
    mode: "hash",
    base: process.env.BASE_URL,
    routes,
});
// router.beforeEach((to, from, next) => {
//   // 1.如果访问的是登录页面（无需权限），直接放行
//   if (to.path === "/login") return next();
//   // 2.如果访问的是有登录权限的页面，先要获取token
//   const tokenStr = sessionStorage.getItem("lc_token");
//   // 2.1如果token为空，强制跳转到登录页面；否则，直接放行
//   if (!tokenStr) {
//     return next("/login");
//   }
//   next();
// });
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};
// router.beforeEach((to, from, next) => {
//   console.log("即将跳转到的路径:", to);
//   let url = to.path;
//
//   const targetUrl = to;
//   let state = store.state.menuList;
//
//   const foundItem = findNodeByURL(state, targetUrl);
//   // console.log("~~~~!!!~~~!!!~!~@~", foundItem);
//   if (foundItem) {
//     console.log("```",foundItem );
//     store.commit("authorityList", foundItem.authority);
//   } else {
//     store.commit("authorityList", []);
//     next();
//   }
//   // console.log("!!!!!!!!!!!!!!!",store.state.authority);
//
//   next();
// });
//
// function findNodeByURL(tree, targetURL) {
//   // 遍历当前层级的节点
//   // let tree=store.state.menuList
//   // console.log(node.url);
//   // console.log(tree,targetURL);
//   for (let node of tree) {
//
//     // console.log(node.url,targetURL.name);
//     if (node.url == targetURL.path && node.children.length == 0 || node.url == targetURL.name && node.children.length == 0 || node.url == targetURL.path ) {
//       // node.url=targetURL.path
//       console.log('11111111', node);
//       if(node.authority && node.children.length >0 ){
//         return node
//       }
//       // console.log("~~~~111~~~~~~~~", node);
//       if (node.children.length == 0) {
//         return node; // 如果找到匹配的节点，直接返回
//       } else {
//         findNodeByURL(node.children, targetURL)
//         // if (node.children[0].children.length == 0 ) {
//         //   console.log("触发了");
//         //   return node.children[0];
//         // } else {
//         //   return node.children[0].children[0];
//         // }
//       }
//     } else if (node.children && node.children.length > 0) {
//       // console.log("111");
//       const foundNode = findNodeByURL(node.children, targetURL); // 递归查找子节点
//       if (foundNode) {
//         return foundNode; // 如果在子节点中找到了匹配的节点，直接返回
//       } else {
//         findNodeByURL(node.children, targetURL);
//       }
//     }
//   }
//   // return []; // 如果在当前层级及其子节点中都没有找到匹配的节点，则返回 null
// }

export default router;
