import { getCode,postRequest ,getRequest,urlTitle} from "@/utils/request";
// import qs from "qs";
// window.qs = qs;
//登录
export const loginApi = {
  // 登录
  login: (params) => {
    return postRequest(`${urlTitle}/system/login`, params);
  },
  getCaptcha: (params) => {
    return getCode(`${urlTitle}/code/verify`,params);
  },
  exit: () => {
    return getRequest(`${urlTitle}/system/logOut`);
  },
  ticketLogin:(params)=>{
    return getRequest(`${urlTitle}/sso/ssoLogin`,params)
  }
};
