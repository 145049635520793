<template>
  <header id="cockpit-header">
    <div class="background">
      <img src="../assets/images/cockpit/logo.png" alt="" />
      <span>{{ stationName }}</span>
    </div>
    <div class="flex-horizontal-between">
      <div class="flex-row">
        <div class="nav home-page" @click="jumpHome">
          <img src="../assets/images/cockpit/home-page.png" alt=""  />
          <span>首页</span>
        </div>
        <div class="nav">
          <img src="../assets/images/cockpit/date.png" alt="" />
          <span>{{ date }} {{ week }} {{ time }}</span>
        </div>
      </div>

      <div class="operate">
        <div class="nav">  
          <img src="../assets/images/cockpit/address.png" alt="" />
          <span>地区：北京  天气：{{ tq.tqxx }} 风向：{{ tq.fxms }} 空气质量：{{ tq.kqzl }}</span>
        </div>
        <div class="user" >
          <img src="../assets/images/cockpit/avatar.png" alt="" />
          <span>{{ userInfo.appellation }}</span>
        </div>
        <div class="line"></div>
        <div class="nav login"  style="margin-right: 32px;" @click="loginOut" >
          <img src="../assets/images/cockpit/exit.png" alt="" />
          <span>退出</span>
        </div>
      </div>
    </div>
  </header>
</template>
<script>
import { loginApi } from "@/api/login.js";
import moment from "moment";
import "moment/locale/zh-cn"; // 引入中文语言包
moment.locale("zh-cn"); // 设置语言为中文
import {monitoringLeftApi} from '@/api/monitoringLeft.js'
import eventBus from "@/api/NoticeEventBus.js";
export default {
  name: 'PanelHeader',

  data() {
    return {
      userInfo:{},
      date: "",
      time: "",
      week: "",
      year: "",
      timer:null,
      stationName: "",
      tq:{
          },
    };
  },

  mounted() {
    this.userInfo = this.$store.state.userInfo;
    this.stationName = sessionStorage.getItem("stationName");
    this.time = moment().format("LTS");
    this.week = moment().format("dddd");
    this.date = moment().format("LL");
    this.year = moment().format("yyyy");
    this.currentTime();
    this.setWeather()
    eventBus.$on("selectedAlarm1", (value) => {
        this.stationName=value
        // console.log("选中的消息", value);
    });
  },

  methods: {
    //天气
    setWeather(){
           // 创建一个 Date 对象
            var now = new Date();
            // 获取当前年份
            var currentYear = now.getFullYear();
            // 获取当前月份（注意，月份是从0开始计数的，所以需要加1）
            var currentMonth = now.getMonth() + 1;
            // 获取当前日期
            var currentDate = now.getDate();
            if(currentMonth<10){
              currentMonth='0' + currentMonth
            }
            if(currentDate<10){
              currentDate='0' + currentDate
            }
            //获取当前小时
            var currentHour = now.getHours();
            let param = {
                qybms:'110000',
                fPeriod:currentYear + "-" + currentMonth + "-" + currentDate +" "+ currentHour +':00',
                tPeriod:currentYear + "-" + currentMonth + "-" + currentDate +" "+ currentHour +':00',
            }
            monitoringLeftApi.getWeatherHourData(param).then(res => {
                if(res.code == 200){
                    this.tq = res.data[0].sdtqInfos[0]
                    // console.log("111111",this.tq);
                    
                }
            })
        },
    currentTime() {
      this.timer = setInterval(() => {
        this.time = moment().format("LTS");
      }, 500);
    },
    jumpHome(){
      this.$router.push('/')
      this.$store.commit('updateStatus', 0);
    },
    loginOut(){
      sessionStorage.clear();
      localStorage.clear();
        loginApi.exit().then((res) => {
        if (res.code === 200) {
          this.$message({
                message: res.message,
                type: "success",
                duration: 3000,
                customClass: "messageText",
              });
              sessionStorage.clear()
              this.$router.push('/login')
            }
          });

        }
  },
  beforeDestroy() {
    if (this.timer) {
      clearInterval(this.timer); // 在Vue实例销毁前，清除时间定时器
    }
  },
};
</script>
<style scoped>
#cockpit-header {
  width: 100%;
  
  height: 102px;
  min-width: 1920px;
  font-size: 16px;
  padding: 31px 0 0 53px;
  box-sizing: border-box;
  position: relative;
  
}
.flex-row {
  display: flex;
  flex-direction: row;
}
.nav {
  display: flex;
  align-items: center;
}
.nav:nth-child(odd) {
  margin-right: 16px;
}
.nav img {
  width: 17px;
  height: 17px;
}
.nav span {
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #bccaf3;
  margin-left: 10px;
}
.background {
  width: 100%;
  height: 92px;
  background: url("../assets/images/cockpit/header-background.png") no-repeat
    center;
  background-size: cover;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.background img {
  width: 45px;
  height: 41px;
  box-shadow: 1px 1px 0px 0px rgba(52, 55, 62, 0.68);
  /* position: absolute;
  top: 22px;
  left: 711px; */
}
.background span {
  font-size: 23px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #eef4fe;
  text-shadow: 0px 3px 1px rgba(2, 7, 37, 0.19);
  /* position: absolute;
  top: 30px;
  left: 777px; */
}
.flex-horizontal-between {
  display: flex;
  justify-content: space-between;
}
.home-page{
  position: relative;
  cursor: pointer;
  z-index: 10;
}
.login{
  cursor: pointer;
  z-index: 10;
}
.operate {
  display: flex;
}
.user{
  display: flex;
  align-items: center;
  margin-left: 21px;
}
.user img {
  width: 28px;
  height: 28px;
}
.user span {
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #e0e8ff;
  margin-left: 8px;
}
.line {
  width: 1px;
  height: 21px;
  background-color: rgb(71, 50, 95);
  margin: 0 11px;
}
</style>
