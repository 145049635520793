import Vue from "vue";
import Vuex from "vuex";
import { messageApi } from "@/api/message.js";
Vue.use(Vuex);
export default new Vuex.Store({
  state: {
    hideList: "",
    selectIndex: "",
    threeChildren: "",
    updateViewNotice: "",
    requestParam: {
      pageNum: 1,
      pageSize: 5,
      messageFlag: 2,
    },
    total: 0,
    componentStatus: sessionStorage.getItem("componentStatus") || 0,
    messageData: [],
    menuList: JSON.parse(sessionStorage.getItem("menu_list")) || [],
    userInfo: JSON.parse(sessionStorage.getItem("user_info")) || {},
    authority:[],
    control:{},//安全管理按钮
  },
  mutations: {
    increment(state, el) {
      state.hideList = el;
    },
    selectChild(state, el) {
      state.selectIndex = el;
    },
    selectChildren(state, el) {
      state.threeChildren = el;
    },
    updateView(state, el) {
      state.updateViewNotice = el;
    },
    updateStatus(state, el) {
      state.componentStatus = el;
      // Vue.set(state, "componentStatus", el);
    },

    updateMenuList(state, el) {
      state.menuList = el;
    },
    updateUserInfo(state, el) {
      state.userInfo = el;
    },
    updateMessage(state, el) {
      if (el.length !== 0) {
        state.total = el[0].count;
      }else{
        state.total = 0;
      }
      state.messageData = el;
    },
    authorityList(state, el) {
      state.authority = el;
    },
    getControl(state, el) {
      state.control = el;
    },
  },
  actions: {
    queryMessage(context) {
      messageApi.queryMessage(context.state.requestParam).then((res) => {
        if (res.code === 200) {
          context.commit("updateMessage", res.data);
        }
      });
    },
  },
  modules: {},
});
