// 综合监控
import {getRequest, postRequest, monitorGateway, urlTitle, gateway} from "@/utils/request";
import {get} from "jquery";
import qs from "qs";

window.qs = qs;
// 消息管理
export const monitoringLeftApi = {
    //场站弹窗查询
    stationDialog: (params) => {
        return getRequest(`${monitorGateway}/monitors/stations/byCompanyId`, params)
    },
    //用能情况统计
    energyStations: () => {
        return getRequest(`${monitorGateway}/monitors/energy/statistics`)
    },
    //设备类型情况统计
    deviceType: () => {
        return getRequest(`${monitorGateway}/monitor/statistics/device/type`)
    },
    //配电形式情况统计
    positionType: () => {
        return getRequest(`${monitorGateway}/monitor/statistics/position/type`)
    },
    //分地区场站统计
    areaStation: (params) => {
        return getRequest(`${monitorGateway}/monitor/statistics/area/station`, params)
    },
    // 获取字典数据
    queryDictionary: (params) => {
        return getRequest(
            `${urlTitle}/station/info/getDictNameAndDictValue`, params);
    },
    //设备寿命情况统计
    deviceLifeTime: (params) => {
        return getRequest(`${monitorGateway}/monitor/statistics/device/lifetime`, params)
    },
    //新闻动态
    getNoticeList: (params) => {
        return getRequest(`${urlTitle}/newNoticeController/getNewNoticeInfo`)
    },
    //获取场站详情综合监控页
    getStationDetail: (params) => {
        return getRequest(`${monitorGateway}/monitors/details`, params)
    },
    //获取当前场站
    getStationCurrent: (params) => {
        return getRequest(`${monitorGateway}/monitors/detail/statistics`, params)
    },
    //用电情况统计
    powerStatistics: (params) => {
        return getRequest(`${monitorGateway}/monitors/power/statistics`, params)
    },
    //获取场站详情
    getDetailStation: (params) => {
        return getRequest(`${monitorGateway}/monitors/station/${params}`)
    },
    //根据场站id获取运维单位
    getStaComList: (params) => {
        return getRequest(`${urlTitle}/station/info/getStaComList`, params)
    },
    //获取场站的温湿水噪
    getStationEnv: (params) => {
        return getRequest(`${monitorGateway}/monitor/station/id`, params)
    },
    //设备数量统计
    getEquipmentNum: (params) => {
        return getRequest(`${monitorGateway}/monitor/station`, params)
    },
    getWeatherHourData: (params) => {
        return postRequest(`${urlTitle}/weatherController/getWeatherHourData`, params)
    },
    statisticsNumber: (params) => {
        return getRequest(`${monitorGateway}/home/page/statistics`, params)
    },

    //配电形式情况统计
    countByType: (params) => {
        return getRequest(`${urlTitle}/station/info/count/byType`, params);
    },
    //配电场站投产年限统计
    withOperationDuration: (params) => {
        return getRequest(`${urlTitle}/station/info/withOperationDuration`, params);
    },
    //发电站寿命情况统计
    selectPowergStationAgeLimit: (params) => {
        return getRequest(`${urlTitle}/synthesis/selectPowergStationAgeLimit`, params);
    },

    //储能场站投产年限
    selectStorageStationAgeLimit: (params) => {
        return getRequest(`${urlTitle}/synthesis/selectStorageStationAgeLimit`, params);
    },

    //聚合能源概况统计
    getAggregatedEnergyStats: (params) => {
        return getRequest(`${urlTitle}/aggregation/module/getAggregatedEnergyStats`, params);
    },
};