<template>
  <el-header id="home-header">
    <div class="logo">
      <img src="../assets/images/home/logo.png" alt="" />
    </div>
    <div class="operate">
      <img src="../assets/images/home/time-icon.png" alt="" class="time-icon" />
      <span style="margin-right: 12px">{{ time }}</span>
      <span>{{ week }}</span>
      <el-popover
        placement="top-start"
        width="383"
        trigger="hover"
        popper-class="message-popover"
        v-if="message==0"
      >
        <div v-if="$store.state.messageData.length!==0" class="popover-content">
          <div
            class="popover-item"
            v-for="(item, index) in $store.state.messageData"
            :key="item.id"
          >
            <div class="message">{{ index + 1 }}、{{ item.sendTxt }}</div>
            <el-link type="primary" @click="jumpDetails(item)">详情<i class="el-icon-arrow-right"></i></el-link>
          </div>
        </div>
        <div v-else class="no-data">
         暂无数据
        </div>
        <div v-if="$store.state.messageData.length!==0" class="popover-footer">
          <el-link type="primary" @click="jumpMessage">点击查看更多>> </el-link>
        </div>
        <div class="popover" slot="reference" >
          <el-badge v-if="$store.state.total!==0" :value="$store.state.total" type="warning">
            <img
              src="../assets/images/home/notice-icon.png"
              alt=""
              class="notice-icon"
            />
          </el-badge>
          <img v-else
              src="../assets/images/home/notice-icon.png"
              alt=""
              class="notice-icon"
            />
        </div>
      </el-popover>
  
      <!-- 用户 -->

      <div class="line"></div>
      <div class="user">
        <img src="../assets/images/home/user.png" alt="" />
        <span>{{ userInfo.appellation }}</span>
      </div>
      <div class="line"></div>
      <img
        src="../assets/images/home/exit.png"
        alt=""
        class="exit-icon"
        @click="exit"
      />
    </div>
  </el-header>
</template>
<script>
import moment from "moment";
import "moment/locale/zh-cn"; // 引入中文语言包
moment.locale("zh-cn"); // 设置语言为中文
import eventBus from "../utils/bus";
import { loginApi } from "@/api/login.js";
import { createLogger } from 'vuex';
export default {
  data() {
    return {
      time: "",
      week: "",
      userInfo: {},
      message:0,
    };
  },
  mounted() {
    this.time = moment().format("YYYY-MM-DD");
    this.week = moment().format("dddd");
    this.$store.dispatch("queryMessage");
    this.userInfo = this.$store.state.userInfo;
    this.message=sessionStorage.getItem("message")
  },

  methods: {
    jumpMessage() {
      this.$router.push("/dailyWork/message");
    },
    exit(){
      loginApi.exit().then((res) => {
        console.log(res)
        if (res.code === 200) {
          this.$message({
                message: res.message,
                type: "success",
                duration: 3000,
                customClass: "messageText",
              });
              sessionStorage.clear()
              this.$router.push('/login')
        }
      });
      // sessionStorage.clear();
    },
    jumpDetails(item){
      // this.$router.push('/dailyWork/message')
      this.$router.push({
            name: 'Message', 
            params:item
        })
      eventBus.$emit("selectedMessage", item);
    }
  },
};
</script>
<style scoped>
#home-header {
  width: 100%;
  min-width: 10.2rem;
  height: 0.8rem !important;
  background-color: rgba(11, 29, 68);
  display: flex;
  justify-content: space-between;
}
.logo {
  height: 0.9rem;
  display: flex;
  align-items: center;
  padding-left: 0.37rem;
}
.el-header {
  padding: 0;
}
.operate {
  font-size: 0.16rem;
  display: flex;
  align-items: center;
  margin-right: 0.32rem;
}
.time-icon {
  width: 0.13rem;
  height: 0.13rem;
  margin: 0 0.1rem 0 0.5rem;
}
.popover {
  padding: 0 0.27rem;
}
.notice-icon {
  width: 0.2rem;
}
.operate :deep() .el-badge__content {
  width: 18px;
  height: 18px;
  line-height: 18px;
  padding: 0;
  /* color: #409EFF; */
}
.operate span {
  font-size: 0.14rem;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #dde7f7;
}
.line {
  width: 0.01rem;
  height: 0.21rem;
  background-color: rgb(71, 50, 95);
}
.user {
  display: flex;
  align-items: center;
  margin-left: 0.25rem;
}
.user img {
  width: 0.4rem;
}
.user span {
  font-size: 0.16rem;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #dde7f7;
  margin: 0 0.2rem 0 0.12rem;
}
.exit-icon {
  width: 0.16rem;
  margin-left: 0.29rem;
}
/* 消息 */
.popover-item {
  height: 40px;
  border-bottom: 1px dashed #09295b;
  display: flex;
  align-items: center;
}
.message {
  width: 286px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 12px;
  color: #aed6ff;
  line-height: 40px;
  cursor: pointer;
  margin-right: 20px;
}
.details {
  height: auto;
  margin-left: 26px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  font-size: 12px;
  color: #4b90e6;
}
.message:hover {
  color: #f5f5f5;
}
.popover-content {
  padding: 5px 19px 0 16px;
  box-sizing: border-box;
}
.popover-footer{
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.no-data{
  font-size: 12px;
  color: #4b90e6; 
  line-height: 50px;
  text-align: center;
}
</style>
<style>
.el-popover.message-popover {
  background: #041c4c;
  border-radius: 3px;
  border: 1px solid #09295b;
}
.el-popper.message-popover[x-placement^="bottom"] .popper__arrow::after {
  border-bottom-color: #041c4c;
}
</style>
