<template>
  <div class="CodeAsideBar">
    <!-- 一级菜单 -->
    <div class="" v-for="(item, index) in list" :key="item.menuName">
      <div
        v-if="!item.hidden && (item.menuName!='驾驶舱-网' && item.menuName!='综合监控')"
        class="title"
        :class="hideList == item.menuName ? 'select' : ''"
        @click="openList(item)"
      >
        <div class="titleL">
          <!-- <img src="../assets/images/asideBar/event.png" alt=""> -->
          <img :src="item.code" alt="" />
          <div>{{ item.menuName }}</div>
        </div>
        <img
          class="titleR"
          v-if="hideList == !item.menuName"
          src="../assets/images/asideBar/arrow1.png"
          alt=""
        />
      </div>
      <!-- 二级菜单 -->
      <ul
        v-show="
          hideList == item.menuName &&
          item.children &&
          item.children.length > 1 &&
          firstShow
        "
        style="padding:0 47px"
      > 
        <div v-for="(itemChildren, index) in item.children" :key="itemChildren.menuName"> 
          <li :class="selectIndex == itemChildren.menuName ? 'selectItem' : ''" v-if="itemChildren.type == 1">
            <div @click="selectIndexFN(itemChildren)" >
              {{ itemChildren.menuName }}
            </div>
         
          <!-- 三级菜单 -->
          <div class="" v-show="selectIndex == itemChildren.menuName && secondShow">
            <div class="" v-for="(threeList, index) in itemChildren.children" :key="threeList.menuName" @click="clickListChildren(threeList)">
              <div class="threeList" :class="threeChildren == threeList.menuName ? 'selectItem': ''"  v-if="threeList.type == 1">{{ threeList.menuName }}</div>
            </div>
          </div> </li>
        </div>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "CodeAsideBar",

  data() {
    return {
      // list: [
      //   {
      //     name: "Eios控制面板",
      //     path: "energyStation",
      //     img: require("../assets/images/asideBar/energyStation.png"),
      //     children: [
      //       {
      //         name: "能源站点",
      //         path: "/eios/energyStation",
      //       },
      //       // {
      //       //   name: "监测回路",
      //       //   path: "/eios/monitoringLoop",
      //       // },
      //       {
      //         name: "巡检单管理",
      //         path: "/eios/inspectionForm",
      //       },
      //       {
      //         name: "巡检项管理",
      //         path: "/eios/inspectionItem",
      //       },
      //       {
      //         name: "试验项管理",
      //         path: "/eios/testItem",
      //       },
      //       {
      //         name: "操作日志",
      //         path: "/event/operation",
      //       },
      //       {
      //         name: "平台运行日志",
      //         path: "/event/platform",
      //       },
      //       {
      //         name: "字典管理",
      //         path: "/eios/dictionary",
      //       },
      //       {
      //         name: "设备管理",
      //         path: "/eios/device",
      //       },
      //       {
      //         name: "单位管理",
      //         path: "/eios/companyInfo",
      //       },
      //       // {
      //       //   name: "场站管理",
      //       //   path: "/eios/station",
      //       // },
      //       {
      //         name: "回路管理",
      //         path: "/eios/adminLoop",
      //       },
      //       {
      //         name: "用户管理",
      //         path: "/eios/user",
      //       },
      //       {
      //         name: "角色管理",
      //         path: "/eios/roleAdmin",
      //       },
      //       {
      //         name: "菜单管理",
      //         path: "/eios/menuAdministration",
      //       },
      //     ],
      //   },
      //   {
      //     name: "事件记录",
      //     path: "event",
      //     img: require("../assets/images/asideBar/event.png"),
      //     children: [
      //       {
      //         name: "遥信事件",
      //         path: "/event/remote",
      //       },
      //       {
      //         name: "告警分析",
      //         path: "/event/alarm",
      //       },
      //       {
      //         name: "短信日志",
      //         path: "/event/sms",
      //       },
      //       {
      //         name: "越限事件",
      //         path: "/event/voltage",
      //         children: [
      //           {
      //             name: "电压",
      //             path: "/event/voltage",
      //           },
      //           {
      //             name: "电流",
      //             path: "/event/current",
      //           },
      //           {
      //             name: "视在功率",
      //             path: "/event/apparent",
      //           },
      //           {
      //             name: "功率因数",
      //             path: "/event/factor",
      //           },
      //           {
      //             name: "变压器温度",
      //             path: "/event/heat",
      //           },
      //           {
      //             name: "变压器噪声",
      //             path: "/event/noise",
      //           },
      //           {
      //             name: "变压器负载",
      //             path: "/event/load",
      //           },
      //           {
      //             name: "电压频率",
      //             path: "/event/frequency",
      //           },
      //           {
      //             name: "环境温度",
      //             path: "/event/temperature",
      //           },
      //           {
      //             name: "环境湿度",
      //             path: "/event/humidity",
      //           },
      //           {
      //             name: "局放",
      //             path: "/event/discharge",
      //           },
      //           {
      //             name: "高压母线",
      //             path: "/event/high",
      //           },
      //           {
      //             name: "低压母线",
      //             path: "/event/low",
      //           },
      //         ],
      //       },
      //     ],
      //   },
      //   {
      //     name: "安全用电",
      //     path: "safety",
      //     img: require("../assets/images/asideBar/safety.png"),
      //     children: [
      //       {
      //         name: "安防联控",
      //         path: "/safety/light",
      //         children: [
      //           {
      //             name: "灯光联动装置",
      //             path: "/safety/light",
      //           },
      //           {
      //             name: "空调联动装置",
      //             path: "/safety/air",
      //           },
      //           {
      //             name: "红外探测器",
      //             path: "/safety/detector",
      //           },
      //           {
      //             name: "智能门锁",
      //             path: "/safety/lock",
      //           },
      //           {
      //             name: "声光报警器",
      //             path: "/safety/audible",
      //           },
      //         ],
      //       },
      //       {
      //         name: "用电安全",
      //         path: "/safety/temperature",
      //         children: [
      //           {
      //             name: "无源无线温度传感器",
      //             path: "/safety/temperature",
      //           },
      //           {
      //             name: "噪声传感器",
      //             path: "/safety/noise",
      //           },
      //           {
      //             name: "变压器温控",
      //             path: "/safety/transformer",
      //           },
      //           {
      //             name: "特高频局放",
      //             path: "/safety/partial",
      //           },
      //           {
      //             name: "变压器声纹",
      //             path: "/safety/vocal",
      //           },
      //         ],
      //       },
      //       {
      //         name: "消防安全",
      //         path: "/safety/smoke",
      //         children: [
      //           {
      //             name: "烟雾传感器",
      //             path: "/safety/smoke",
      //           },
      //           {
      //             name: "温湿度传感器",
      //             path: "/safety/humidity",
      //           },
      //           {
      //             name: "SF6+O3传感器",
      //             path: "/safety/sensor",
      //           },
      //           {
      //             name: "水浸",
      //             path: "/safety/water",
      //           },
      //         ],
      //       },
      //     ],
      //   },
      //   // 运维管理
      //   {
      //     name: "运维管理",
      //     path: "inspectionTask",
      //     img: require("../assets/images/asideBar/inspectionTask.png"),
      //     children: [
      //       // {
      //       //   name: "任务管理",
      //       //   path: "/operationManagement/operationTask",
      //       // },
      //       {
      //         name: "巡检任务",
      //         path: "/operationManagement/inspectionTask",
      //       },
      //       {
      //         name: "维修任务",
      //         path: "/operationManagement/taskList",
      //       },
      //       {
      //         name: "维修记录",
      //         path: "/operationManagement/operationService",
      //       },
      //       {
      //         name: "抢修记录",
      //         path: "/operationManagement/operationEmergency",
      //       },
      //       {
      //         name: "试验任务",
      //         path: "/operationManagement/testTask",
      //       },
      //       {
      //         name: "消警记录",
      //         path: "/operationManagement/operationWarning",
      //       },
      //       {
      //         name: "抢修任务",
      //         path: "/operationManagement/rushRepair",
      //       },
      //     ],
      //   },
      //   {
      //     name: "考核管理",
      //     path: "Management",
      //     img: require("../assets/images/asideBar/Management.png"),
      //   },
      //   {
      //     name: "资产管理",
      //     path: "Administration",
      //     img: require("../assets/images/asideBar/Administration.png"),
      //   },
      //   {
      //     name: "安全管理",
      //     path: "Control",
      //     img: require("../assets/images/asideBar/Control.png"),

      //     children: [
      //       {
      //         name: "安防联控",
      //         path: "/security/control",
      //         children: [
      //           {
      //             name: "灯光联动装置",
      //             path: "/security/control",
      //           },
      //           {
      //             name: "空调联动装置",
      //             path: "/security/conditioning",
      //           },
      //           {
      //             name: "红外探测器",
      //             path: "/security/infrared",
      //           },
      //           {
      //             name: "智能门锁",
      //             path: "/security/doorLock",
      //           },
      //           {
      //             name: "声光报警器",
      //             path: "/security/acoustoOptic",
      //           },
      //         ],
      //       },
      //       {
      //         name: "状态评价",
      //         path: "/security/temperature",
      //         children: [
      //           {
      //             name: "无源无线温度传感器",
      //             path: "/security/temperature",
      //           },
      //           {
      //             name: "噪声传感器",
      //             path: "/security/noise",
      //           },
      //           {
      //             name: "变压器温控",
      //             path: "/security/transformer",
      //           },
      //           {
      //             name: "特高频局放",
      //             path: "/security/frequency",
      //           },
      //           {
      //             name: "变压器声纹",
      //             path: "/security/voicePrint",
      //           },
      //         ],
      //       },
      //       {
      //         name: "环境监测",
      //         path: "Smoke",
      //         children: [
      //           {
      //             name: "烟雾传感器",
      //             path: "/security/smoke",
      //           },
      //           {
      //             name: "温湿度传感器",
      //             path: "/security/humidity",
      //           },
      //           {
      //             name: "SF6+O3传感器",
      //             path: "/security/sf6o3",
      //           },
      //           {
      //             name: "水浸",
      //             path: "/security/water",
      //           },
      //         ],
      //       },
      //     ],
      //   },
      //   {
      //     name: "能源管理",
      //     path: "Equipment",
      //     img: require("../assets/images/asideBar/Equipment.png"),
      //     children: [
      //       {
      //         name: "能耗分析",
      //         path: "Equipment",
      //         children: [
      //           {
      //             name: "设备能耗",
      //             path: "/energy/equipment",
      //           },
      //           {
      //             name: "变压器",
      //             path: "/energy/energyTransformer",
      //           },
      //           {
      //             name: "特高频",
      //             path: "/energy/highFrequency",
      //           },
      //           {
      //             name: "局放",
      //             path: "/energy/partial",
      //           },
      //         ],
      //       },
      //       {
      //         name: "碳排分析",
      //         path: "/energy/emissionsCarbon",
      //       },
      //       {
      //         name: "能源运营",
      //         path: "/energy/operations",
      //       },
      //     ],
      //   },
      //   {
      //     name: "日常办公",
      //     path: "Document",
      //     img: require("../assets/images/asideBar/Document.png"),
      //     children: [
      //       {
      //         name: "文档管理",
      //         path: "/dailyWork/document",
      //       },
      //       {
      //         name: "员工通讯录",
      //         path: "/dailyWork/staffAddressBook",
      //       },
      //       {
      //         name: "客户通讯录",
      //         path: "/dailyWork/customerAddressBook",
      //       },
      //       {
      //         name: "意见反馈",
      //         path: "/dailyWork/feedback",
      //       },
      //       {
      //         name: "公告管理",
      //         path: "/dailyWork/notice",
      //       },
      //       {
      //         name: "消息管理",
      //         path: "/dailyWork/message",
      //       },
      //     ],
      //   },
      //   {
      //     name: "知识库",
      //     path: "Library",
      //     img: require("../assets/images/asideBar/Library.png"),
      //   },
      //   {
      //     name: "用电分析",
      //     path: "analysis",
      //     img: require("../assets/images/asideBar/analysis.png"),
      //     children: [
      //       {
      //         name: "用能报表",
      //         path: "/analysis/energy",
      //       },
      //       {
      //         name: "同比分析",
      //         path: "/analysis/year",
      //       },
      //       {
      //         name: "环比分析",
      //         path: "/analysis/month",
      //       },
      //       {
      //         name: "电能集抄",
      //         path: "/analysis/electricity",
      //       },
      //       {
      //         name: "分时段用电",
      //         path: "/analysis/period",
      //       },
      //       {
      //         name: "最大需量报表",
      //         path: "/analysis/demand",
      //       },
      //       {
      //         name: "用电负荷",
      //         path: "/analysis/load",
      //       },
      //       {
      //         name: "实时需量",
      //         path: "/analysis/now",
      //       },
      //       {
      //         name: "用能及电费报表",
      //         path: "/analysis/cost",
      //       },
      //       {
      //         name: "整点集抄",
      //         path: "/analysis/integral",
      //       },
      //     ],
      //   },
      //   {
      //     name: "电力监测",
      //     path: "monitor",
      //     img: require("../assets/images/asideBar/monitor.png"),
      //     children: [
      //       {
      //         name: "配电图",
      //         path: "/monitor/distribution",
      //       },
      //       {
      //         name: "高压侧",
      //         path: "/monitor/main",
      //         children: [
      //           {
      //             name: "主进线",
      //             path: "/monitor/main",
      //           },
      //           {
      //             name: "馈线",
      //             path: "/monitor/feeder",
      //           },
      //         ],
      //       },
      //       {
      //         name: "低压侧",
      //         path: "/monitor/low",
      //         children: [
      //           {
      //             name: "电力极值报表",
      //             path: "/monitor/extreme",
      //           },
      //           {
      //             name: "电力运行日报",
      //             path: "/monitor/running",
      //           },
      //           {
      //             name: "平均功率因数",
      //             path: "/monitor/power",
      //           },
      //         ],
      //       },
      //       {
      //         name: "变压器",
      //         path: "/monitor/transformer",
      //       },
      //       {
      //         name: "直流屏",
      //         path: "/monitor/screen",
      //       },
      //     ],
      //   },
      //   {
      //     name: "电能质量",
      //     path: "quality",
      //     img: require("../assets/images/asideBar/quality.png"),
      //     children: [
      //       {
      //         name: "谐波监测",
      //         path: "/quality/wave",
      //       },
      //     ],
      //   },
      //   {
      //     name: "运行环境",
      //     path: "environment",
      //     img: require("../assets/images/asideBar/environment.png"),
      //     children: [
      //       {
      //         name: "环境监测",
      //         path: "/environment/monitor",
      //       },
      //       {
      //         name: "视频监控",
      //         path: "/environment/now",
      //         children: [
      //           {
      //             name: "实时监控",
      //             path: "/environment/now",
      //           },
      //           {
      //             name: "监控回放",
      //             path: "/environment/playback",
      //           },
      //         ],
      //       },
      //       {
      //         name: "视频设备状态",
      //         path: "/environment/videoDevice",
      //       },
      //       {
      //         name: "仪表通讯状态",
      //         path: "/environment/instrument",
      //       },
      //       {
      //         name: "网关通信状态",
      //         path: "/environment/gateway",
      //       },
      //     ],
      //   },
      //   {
      //     name: "统计分析",
      //     path: "StatisticsAnalysis",
      //     img: require("../assets/images/asideBar/StatisticsAnalysis.png"),
      //   },
      //   {
      //     name: "计划管理",
      //     path: "inspectionPlan",
      //     img: require("../assets/images/asideBar/inspectionPlan.png"),
      //     children: [
      //       // {
      //       //   name: "巡视计划",
      //       //   path: "/planAdministration/patrol",
      //       // },
      //       // {
      //       //   name: "检修计划",
      //       //   path: "/planAdministration/overhaul",
      //       // },
      //       {
      //         name: "巡检计划",
      //         path: "/planAdministration/inspectionPlan",
      //       },
      //       {
      //         name: "试验计划",
      //         path: "/planAdministration/testPlan",
      //       },
      //       {
      //         name: "培训计划",
      //         path: "/planAdministration/train",
      //       },
      //       {
      //         name: "采购计划",
      //         path: "/planAdministration/purchase",
      //       },
      //       {
      //         name: "停电计划",
      //         path: "/planAdministration/blackout",
      //       },
      //     ],
      //   },
      //   {
      //     name: "碳排分析",
      //     path: "RealTime",
      //     img: require("../assets/images/asideBar/Library.png"),
      //     children: [
      //       {
      //         name: "实时碳排",
      //         path: "/emissionsCarbon/realTime",
      //       },
      //       {
      //         name: "碳排统计",
      //         path: "/emissionsCarbon/statistics",
      //       },
      //       {
      //         name: "累计碳排",
      //         path: "/emissionsCarbon/cumulative",
      //       },
      //       {
      //         name: "同比分析",
      //         path: "/emissionsCarbon/yearOnYear",
      //       },
      //       {
      //         name: "环比分析",
      //         path: "/emissionsCarbon/monthOnMonth",
      //       },
      //     ],
      //   },
      // ],
      list: [],
      hideList: "",
      selectTitle: "",
      selectIndex: "",
      threeChildren: "",
      addressClick: "",
      firstShow: true,
      secondShow: true,
      thirdShow: false,
      oldName: "",
      filterMenu: ["驾驶舱-网", "综合监控"],
    };
  },

  mounted() {
    // this.$store.commit('increment',"abc")
    this.hideList = this.$store.state.hideList;
    console.log("123qw",this.hideList);
    this.selectIndex = this.$store.state.selectIndex;
    this.threeChildren = this.$store.state.threeChildren;
    const filterMenu = ["驾驶舱-网", "综合监控", "安全用电"];
    this.list = this.$store.state.menuList;
    this.list = this.$store.state.menuList.filter(item =>{
      if(item.children && item.children.length>1){
        item.children.forEach((Element,index) => {
          if(Element.hidden){
            item.children.splice(index,1)
          }
        })
      }
    if(!filterMenu.includes(item.menuName)){
    item.code = require(`../assets/images/asideBar/${item.code}.png`);
    }
    return !filterMenu.includes(item.menuName)
    });
  },

  watch: {
    "$store.state.selectIndex": {
      //深度监听，可监听到对象、数组的变化
      handler(val, oldVal) {
        // console.log(val, oldVal)
        this.hideList = val;
        console.log("val",this.hideList);
      },
      deep: true, //true 深度监听
    },
    "$store.state.selectIndex": {
      //深度监听，可监听到对象、数组的变化
      handler(val, oldVal) {
        // console.log(val, oldVal)
        this.selectIndex = val;
        console.log("val",val);
      },
      deep: true, //true 深度监听
    },
    "$store.state.addressClick": {
      //深度监听，可监听到对象、数组的变化
      handler(val, oldVal) {
        // console.log("~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~");
        // console.log(val, oldVal)
        this.addressClick = val;
      },
      deep: true, //true 深度监听
    },
  },
  methods: {
    transformData(data) {
      data.forEach((item) => {
        if (item.children && item.children.length > 0) {
          const type2Menus = item.children.filter((child) => {
            if (child.type === 2) {
              return child;
            }
          });
          console.log("11112222", type2Menus);
          if (type2Menus.length > 0) {
            item.authority = [];
            type2Menus.reduce((acc, cur) => {
              if (cur.children && cur.children.length > 0) {
                acc.push(
                  ...cur
                    .filter((child) => child.code)
                    .map((child) => child.code)
                );
              }
              // console.log("~~~!!@@",acc,cur);
              item.authority.push(cur.code);
              // return acc;
            }, item.authority || []);
            item.children = item.children.filter((child) => child.type !== 2);
            console.log("2222333", item.authority);
          }
        }
        if (item.children && item.children.length > 0) {
          this.transformData(item.children);
        }
      });
      // console.log("@#$#%$@$@@~~~~~~~",data);
      return data;
    },

    //是否展示子级
    openList(index) {
      console.log("i111", index);
      this.hideList = index.menuName;
      this.secondShow = true;
      this.$store.commit("increment", index.menuName);
      // sessionStorage.setItem("first_menu", index.name);
      // this.selectTitle=
      if (index.children && index.children.length > 1 && index.children[0].type==1) {
        this.firstShow = !this.firstShow;
        let address = index.children[0];
        // if (!address.children) {
        this.selectIndex = address.menuName;
        this.$store.commit("selectChild", address.menuName);
        // sessionStorage.setItem("second_menu", address.name);
        if (address.children && address.children.length > 1 && address.children[0].type==1) {
          console.log("q111111111");
          if (this.threeChildren == "") {
            this.firstShow = true;
            this.threeChildren = address.children[0].menuName;
            console.log("threeChildren", this.threeChildren);
            this.addressClick = address.children[0].url;
            this.$store.commit("selectChildren", address.children[0].menuName);
            // sessionStorage.setItem("second_menu", address.children[0].name);
            this.$router.push(this.addressClick);
          } else {
            if (!this.addressClick) {
              this.threeChildren = address.children[0].menuName;
              this.addressClick = address.children[0].url;
              this.$store.commit(
                "selectChildren",
                address.children[0].menuName
              );
            } else {
              console.log(this.oldName);
              if (this.oldName !== index.menuName) {
                this.threeChildren = address.children[0].menuName;
                this.addressClick = address.children[0].url;
                this.$store.commit(
                  "selectChildren",
                  address.children[0].menuName
                );
              }
            }
            this.oldName = index.menuName;
            console.log(
              "11111111",
              this.threeChildren,
              this.addressClick,
              "22qw"
            );
            this.$router.push(this.addressClick);
          }
        } else {
          console.log(address);
          // console.log("22222222222222222");
          this.$router.push(address.url);
        }
        // }
      } else {
        this.$router.push({
          path: index.url,
        });
      }
      // this.$router.push(address.url)
    },
    //点击二级菜单
    selectIndexFN(index) {
      // console.log(index);
      console.log("indexindexindexindex", index);
      this.secondShow = !this.secondShow;
      this.selectIndex = index.menuName;
      if (!index.children || index.children.length < 1) {
        this.$store.commit("selectChild", index.menuName);
        // sessionStorage.setItem("second_menu", index.name);
      } else {
        let address = index.children[0];
        this.threeChildren = address.menuName;
        this.addressClick = index.url;
        this.$store.commit("selectChild", index.menuName);
        // sessionStorage.setItem("second_menu", index.name);
      }
      this.$router.push(index.url);
    },
    clickListChildren(el) {
      this.$store.commit("selectChildren", el.menuName);
      // sessionStorage.setItem("third_menu", el.name);
      this.threeChildren = el.menuName;
      this.addressClick = el.url;
      console.log("elelelelelelelel", this.threeChildren);
      this.$router.push(el.url);
    },
  },
};
</script>

<style scoped>
.CodeAsideBar {
  /* height:90%; */
  padding: 20px 0px;
  font-size: 16px;

  overflow: auto;
  position: relative;
}

.title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #fff;
  font-size: 14px;
  padding: 0 18px;
  cursor: pointer;
}

.titleL {
  display: flex;
  align-items: center;
  height: 45px;
  font-size: 14px;
}

.titleL img {
  width: 20px;
  height: 20px;
  margin-right: 2px;
}

ul li {
  line-height: 40px;
  font-size: 12px;
  padding: 0px 20px;
}

ul {
  background-color: rgba(48, 149, 255, 0.06);
  margin: 0;
  padding: 20px 47px;
  cursor: pointer;
}

ul > li:hover {
  color: rgba(255, 199, 74, 0.88);
}

.title:hover {
  background: url(../assets/images/asideBar/nav.png);
  background-size: cover;
  background-repeat: no-repeat;
}

.title:hover .titleR {
  display: none;
}

.select {
  background: url(../assets/images/asideBar/nav.png);
  background-size: cover;
  background-repeat: no-repeat;
  /* background-position: center center; */
}

.threeList {
  height: 40px;
  line-height: 40px;
  padding-left: 20px;
  color: #9ac0e9;
  cursor: pointer;
}

.threeList:hover {
  color: rgba(255, 199, 74, 0.88);
  /* pointer-events: none; */
}

.selectItem {
  color: rgba(255, 199, 74, 0.88);
}
.clear-padding {
  padding: 0;
}
</style>
