import axios from "axios";
import cookie from "js-cookie";
import { Message, Loading } from "element-ui";
// import 'element-ui/lib/theme-chalk/index.css';
// import { Toast } from 'vant'
// import { baseUrl } from '@/config/index'
// create an axios instance
import router from "../router/index.js";
const service = axios.create({
  // baseURL: 'http://localhost:8080', // url = base url + request url
  // baseURL: 'http://192.168.2.123:9111/', // url = base url + request url
  baseURL: 'https://api.lenengshi.com:21111/', // url = base url + request url
  timeout: 700000, // request timeout
  headers: {
    "Content-Type": "application/json", // 设置默认请求头
  },
});

let loading;
function startLoading() {
  // element-ui loading 服务调用方式
  loading = Loading.service({
    // lock: true,
    text: "加载中...",
    // spinner: 'el-icon-loading',  // 自定义图标
    background: "rgba(0, 0, 0, 0.7)",
  });
}

// loading结束 方法
function endLoading() {
  loading.close();
}
// request interceptor
service.interceptors.request.use(
  (config) => {
    // do something before request is sent
    // config.headers['token'] = 'c9j4kajm0jncp0u96570'
    if (sessionStorage.getItem("lc_token")) {
      // config.headers["Authorization"] = sessionStorage.getItem("lc_token");
      config.headers["token"] = sessionStorage.getItem("lc_token");
    }
    //不需要loading的接口在个数组中添加
    let arr = ["/power-system/sysfile/upload", "/power-system/code/verify",];
    if (!config.url.includes(arr)) {
      // loading开始
      startLoading();
    }
    // config.headers['Content-Type']= 'application/x-www-form-urlencoded'

    // if (cookie.get('lc_token')) {
    //   config.headers['Authorization'] =
    //   cookie.get('lc_token')
    // }
    return config;
  },
  (error) => {
    // do something with request error
    console.log(error); // for debug
    return Promise.reject(error);
  }
);
service.interceptors.response.use(
  (res) => {
    // 在响应返回之后关闭加载动画
    endLoading();
    if (res.data.code === 401||res.data.code === 402) {
      Message({
        message: res.data.msg,
        type: "error",
        duration: 3000,
        customClass: "messageText",
      });
      router.push("/login");
      sessionStorage.clear();
    }
    return res.data;
  },
  (err) => {
    // 在响应返回之后关闭加载动画
    endLoading();
    console.log(err);
    if (err && err.response) {
      switch (err.response.status) {
        case 401:
          err.message = "未授权，请登录";
          // router.push("/login");
          break;
        case 403:
          err.message = "拒绝访问";
          break;
        case 404:
          err.message = `请求地址出错: ${err.response.config.url}`;
          break;
        case 500:
          err.message = `数据库重要数据解密验签错误`;
          break;
        default:
          if (err.response.data) {
            err.message = " ";
            if (err.response.data.message) {
              //JAVA异常抛出辑错误信息
              err.message += JSON.stringify(err.response.data.message);
            }
            if (err.response.data.errorText) {
              //业务逻辑错误信息
              err.message += JSON.stringify(err.response.data.errorText);
            }
            err.message = "服务器异常";
            // err.message = err.response.data.message
          } else {
            err.message = "服务器异常";
            // err.message = err.response.data.message
          }
      }
      // console.log(err.message);
      Message({
        message: err.message,
        type: "error",
        duration: 3000,
        customClass: "messageText",
      });
    }
    // alert(err.message)
    // return Promise.reject(err)
  }
);

export default service;
export const request = (config) => {
  return service.request(config);
};
export const getRequest = (url, params) => {
  return request({ url, method: "get", params });
};
export const getCode = (url, params) => {
  return request({ url, method: "get", responseType: "arraybuffer", params });
};
export const getDownLoad = (url, params) => {
  return request({ url, method: "get", responseType: "blob", params });
};
export const postDownLoad = (url, params) => {
  return service.post(url, params,{responseType: "blob"});
};
export const getDownLoadT = (url, params) => {
  return service.get(url, params,{responseType: "blob"});
};
export const postRequest = (url, params) => {
  return service.post(url, params);
};
export const pictureRequest = (url, params, headers) => {
  console.log("headers", headers);
  return service.post(url, params, { headers });
};
export const urlTitle = "/power-system";
export const gateway = "/power-operation";
export const monitorGateway = "/power-analysis";
// export const urlTitle = "";
// export const gateway = "";
