import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import cookie from 'js-cookie'
import $ from 'jquery';
//全局引入echarts
import * as echarts from 'echarts';
import { Base64 } from 'js-base64';
import './utils/rem.js'
//需要挂载到Vue原型上
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import md5 from 'js-md5';
import { LMap, LTileLayer, LMarker } from 'vue2-leaflet';
import 'leaflet/dist/leaflet.css';
import VScaleScreen from 'v-scale-screen'
import "@/utils/style/public.css"


Vue.prototype.$md5 = md5;
Vue.use(ElementUI);
Vue.use(VScaleScreen)
Vue.prototype.$imgurl = 'http://117.78.46.75:8080/qinergyserver'
Vue.prototype.$http = axios;
Vue.prototype.$echarts = echarts;
Vue.prototype.$cookie = cookie;
Vue.prototype.$Base64 = Base64;
Vue.config.productionTip = false
axios.defaults.baseURL =  'http://192.168.0.106:8080' 

// Vue.mixin({
//   methods: {
//     handleGlobalKeyDown(event) {
//       if (event.key === 'Enter' || event.key === ' ') {
//         event.preventDefault();
//         document.activeElement.blur(); // 取消当前元素的焦点，避免再次触发点击事件
//       }
//     }
//   },
//   mounted() {
//     window.addEventListener('keydown', this.handleGlobalKeyDown);
//   },
//   beforeDestroy() {
//     window.removeEventListener('keydown', this.handleGlobalKeyDown);
//   }
// });


Vue.component('l-map', LMap);
Vue.component('l-tile-layer', LTileLayer);
Vue.component('l-marker', LMarker);
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
