<template>
    <footer id="footer">
      <div class="flex-vertical-center">
        <div class="returnForm" @click="returnForm">
          <img src="../assets/images/home/homepage.png" alt="" class="homepage" />
        </div>
        <ul class="breadcrumb">
          <div v-if="firstMenu" @click="jumpFirstMenu">
            <li class="circle"></li>
            <li>{{ firstMenu }}</li>
          </div>
          <div v-if="secondMenu" @click="jumpSecondMenu">
            <li><i class="el-icon-arrow-right arrow"></i></li>
            <li class="circle"></li>
            <li>{{ secondMenu }}</li>
          </div>
          <div v-if="thirdMenu">
            <li><i class="el-icon-arrow-right arrow"></i></li>
            <li class="circle"></li>
            <li>{{ thirdMenu }}</li>
          </div>
        </ul>
      </div>
      <div class="content">
        <!-- <div class="icon">  -->
        <img src="../assets/images/componentsImage/noticeIcon.png" alt="" />
        <!-- </div> -->

        <div class="notice-content">
          <div v-for="item in boardData" :key="item.title" class="notice">
            <span>{{ item.title }}</span>
            <span>{{ item.content }}</span>
          </div>
        </div>
      </div>
      <div class="warn">
        <!-- <div>
          <el-button type="primary"> 严重告警 </el-button>
          <img src="../assets/images/home/serious.png" alt="" />
        </div>
        <div>
          <el-button type="warning"> 重大告警</el-button>
          <img src="../assets/images/home/major.png" alt="" />
        </div>
        <div>
          <el-button type="danger"> 特别重大告警</el-button>
          <img src="../assets/images/home/most.png" alt=""  style="left: 12px;"/>
        </div>  -->
      </div>
      <div class="alarmList2" v-show="alarmDialog" @click="goAlarm(gjDetail)" :style="{'bottom':show?'3.8rem':''}">
        <div class="alarmTitle">
          {{gjTitle}}
        </div>
        <div class="alarmItem2">
          <img src="@/assets/images/alarm/alarm2.png" alt="">
          <span style="font-size:14px;color:#c9d4f1;">{{content}}</span>
        </div>
        <div style="font-size:14px;color:#c9d4f1;position:absolute;right:10px;bottom:10px;">告警时间:{{gjCreateTime}}</div>
        <div class="dialogList2" @click.stop="alarmDialog=false">
          <i class="el-icon-close" style="font-size: 20px;"></i>
        </div>
      </div>
      <div class="alarmList" v-show="show" >
        <div class="alarmItem" v-for="(item,index) in alarmListShowData" :key="index" :class="`alarm${item.levelId}`" @click="goAlarm(item)">
          <img v-if="item.levelId==1" src="@/assets/images/alarm/alarm1.png" alt="">
          <img v-if="item.levelId==2" src="@/assets/images/alarm/alarm2.png" alt="">
          <img v-if="item.levelId==3" src="@/assets/images/alarm/alarm3.png" alt="">
          <img v-if="item.levelId==4" src="@/assets/images/alarm/alarm4.png" alt="">
          <div style=" overflow: hidden;  white-space: nowrap; text-overflow: ellipsis;">{{ item.title }}:{{ item.content }}</div>
        </div>
        <div class="" style="text-align: center; cursor: pointer; " @click="goAlarmList(1)">查看更多 >>></div>
        <div class="dialogList" @click="show=false">
          <i class="el-icon-circle-close" style="font-size: 20px;"></i>
        </div>
      </div>
      <div v-if=" isStringInArray('btnAlarmShow')" style="margin-right: 60px; position: relative; z-index: 100;">
        <el-badge :value="alarmListData[2]?.total" class="item" type="warning" style="margin-right: 32px;">
          <div class="bottom tip" @click="alarmShow(2)">提示</div>
        </el-badge>
        <el-badge :value="alarmListData[1]?.total" class="item" type="warning" style="margin-right: 32px;">
          <div class="bottom warning" @click="alarmShow(1)">预警</div>
        </el-badge>
        <el-badge :value="alarmListData[0]?.total" class="item" type="warning">
          <div class="bottom alarm" @click="alarmShow(0)">报警</div>
        </el-badge>
      </div>
    </footer>
</template>
<script>
import { board } from "../api/dailyWork.js";
import eventBus from "../api/NoticeEventBus.js";
import { alarmApi } from "@/api/alarm.js";
export default {
  name: "bottomNotice",

  data() {
    return {
      boardData: [1, 2, 3],
      alarm:[],
      show:false,
      alarmDialog:false,
      alarmNum:'',
      gjCreateTime:'',
      gjTitle:'',
      content:'',
      authorityListBtn:[],
      gjDetail:{},
      alarmTimer: null,
      alarmTypeNum: 0,
      alarmListData: [[
        {alarms: [], total: 0}
      ]]
    };
  },
  created(){
    this.alarmTimer = setInterval(() => {
      this.queryMessage();
      this.getAlertMessage();
    }, 300000);
  },
  mounted() {
    this.getBoard();
    this.authorityListBtn=sessionStorage.getItem('roleInfo')
    this.queryMessage()
    this.getAlertMessage()
    eventBus.$on("queryMessage", () => {
      console.log("底部导航更新");
      this.queryMessage();
      this.getAlertMessage();
      this.getBoard();
    });
    // 更改公告列表之后更新底部导航
    eventBus.$on("notices-updated-notice", () => {
      console.log("底部导航更新");
      this.getBoard();
    });
    // eventBus.$on("notices-updated-alarm", () => {
    //   console.log("告警更新");
    //   this.queryMessage()
    // });
  },

  methods: {
       //按钮权限
    isStringInArray(str) {
      if (this.authorityListBtn) {
        let list = this.authorityListBtn.includes(str);
        return list
      } else {
        return false
      }
    },
    notice() {
      console.log("点击item");
    },
    jumpFirstMenu() {
      this.$store.state.menuList.forEach((item) => {
        if (item.children.length !== 0) {
          if (item.menuName === this.$store.state.hideList) {
            this.$router.push(item.children[0].url);
          }
          // if (this.$route.path === item.children[0].url) {

          // }
        }
      });
    },
    jumpSecondMenu() {
      this.$store.state.menuList.forEach((element) => {
        element.children.forEach((item) => {
          if (item.children.length !== 0) {
            if (item.menuName === this.$store.state.selectIndex) {
              this.$router.push(item.children[0].url);
            }
          }
        });
      });
    },
    returnForm() {
      this.$router.push("/");
    },
    goAlarmList(type){
      this.$router.push({
        name:"AllAlarm",
        query:{
          id:'1',
          type:type
        }
      });
      // sessionStorage.setItem("componentStatus", 1);
    // this.$store.commit("updateStatus", 1)
    },
    goAlarm(item){
      eventBus.$emit("selectedAlarm", item);
      eventBus.$emit("updateDialog", item);
      this.$router.push({
        name:"AllAlarm",
        params:item
      });
      // eventBus.$emit('queryMessage')
    //   sessionStorage.setItem("componentStatus", 1);
    // this.$store.commit("updateStatus", 1)
    },
    queryMessage() {
      alarmApi.AlarmList({pageNum:1,pageSize:5}).then((res) => {
        if (res.code === 200) {
          this.alarmListData = res.data;
          this.alarmNum=res.total
        }
      });
    },
    getAlertMessage(){
      alarmApi.AlertAlarm().then((res) => {
        if (res.code === 200) {
          if(res.data){
            this.gjDetail = res.data
            this.gjTitle = res.data.title
            this.content = res.data.content
            this.gjCreateTime = res.data.createTime
            this.alarmDialog = true
          }else{
            this.alarmDialog = false
          }
        }
      });
    },
    getBoard() {
      this.boardData=[]
      board().then((res) => {
        console.log(res);
        if (res.code == 200) {
          this.boardData = res.data;
        }
      });
    },
    alarmShow(num){
      if(this.alarmTypeNum == num){
        if(this.show){
          this.show =! this.show;
        }else{
          this.show =! this.show;
        }
      }else{
        if(this.show){

        }else{
          this.show =! this.show;
        }
      }
      this.alarmTypeNum = num;
    }
  },
  computed: {
    firstMenu() {
      let firstMenu;
      if (this.$store.state.hideList) {
        firstMenu = this.$store.state.hideList;
      }
      return firstMenu;
    },
    secondMenu() {
      let secondMenu;
      if (this.$store.state.selectIndex) {
        secondMenu = this.$store.state.selectIndex;
      }
      return secondMenu;
    },
    thirdMenu() {
      let thirdMenu;
      if (this.$store.state.threeChildren) {
        thirdMenu = this.$store.state.threeChildren;
      }
      return thirdMenu;
    },
    alarmListShowData() {
      let temp = [];
      switch (this.alarmTypeNum) {
        case 0:
          temp = this.alarmListData[this.alarmTypeNum]?.alarms;
          break;
        case 1:
          temp = this.alarmListData[this.alarmTypeNum]?.earlyWarnings;
          break;
        case 2:
          temp = this.alarmListData[this.alarmTypeNum]?.prompts;
          break;
        default:
          temp = this.alarmListData[0]?.alarms;
          break;
      }
      return temp
    }
  },
  destroyed() {
    clearInterval(this.alarmTimer);
    this.alarmTimer = null;
  }
};
</script>

<style scoped>
#footer {
  width: 100%;
  min-width: 10.2rem;
  height: auto;
  background: rgb(5, 37, 86);
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 16px;
}
.flex-vertical-center {
  display: flex;
  align-items: center;
}
.returnForm {
  cursor: pointer;
}
.homepage {
  width: 0.14rem;
  height: 0.14rem;
  margin: 0 0.19rem;
}
.breadcrumb {
  /* width: 2.96rem; */
  height: 0.31rem;
  background-color: rgb(4, 49, 101);
  margin: 0;
  padding: 0 16px;
  list-style: none;
  display: flex;
}
.breadcrumb div {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.breadcrumb .circle {
  width: 0.06rem;
  height: 0.06rem;
  background: #ffffff;
  border-radius: 50%;
  margin-right: 0.09rem;
}
.breadcrumb li {
  font-size: 0.12rem;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  line-height: 0.31rem;
}
.arrow {
  margin: 0 0.09rem;
}
.notice {
  display: flex;
  align-items: center;
  position: relative;
  animation: move 20s linear infinite;
}
@keyframes move {
  0% {
    right: -520px;
  }
  100% {
    right: calc(100% + 520px);
  }
}
.notice span {
  font-size: 0.12rem;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  margin-left: 0.08rem;
  white-space: nowrap;
}
.notice span:first-child::after {
  content: ":";
  margin: 0 3px;
}
.warn {
  height: 100%;
  display: flex;
  overflow: hidden;
  padding-right: 0.22rem;
}
.warn :deep() .el-button {
  width: 1.25rem;
  height: 0.37rem;
  border-radius: 0.08rem;
  font-size: 0.14rem;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #dde7f7;
  margin-right: 0.1rem;
  padding-left: 0.32rem;
}
.warn div {
  position: relative;
}
.warn img {
  position: absolute;
  top: 0.1rem;
  left: 0.18rem;
}

.el-carousel__item h3 {
  /* color: #f7f8fa; */
  font-size: 14px;
  opacity: 0.75;
  line-height: 15px;
  margin: 0;
}

.notice-content {
  width: 920px;
  display: flex;
  overflow: hidden;
}
.content {
  display: flex;
  align-items: center;
  height: 16px;
}
.contentL {
  font-size: 16px;
}
img {
  width: 16px;
  height: 16px;
  margin-right: 10px;
}
.bottom{
  position: relative;
  bottom: 5px;
  margin-right: -6px;
  display: inline-block;
  cursor: pointer;
  padding: 0 12px 0 38px;
  height: 40px;
  line-height: 40px;
  background-size: 20px; /* 设置背景图大小为cover */
  background-position: left 12px center;
  background-repeat: no-repeat; /* 防止背景图平铺 */
  border-radius: 10px 10px 0 0 ;
}
.bottom.tip{
  background-color: #1773c6;
  background-image: url('../assets/images/alarm/alarm2.png');
}
.bottom.warning{
  background-color: #eb6100;
  background-image: url('../assets/images/alarm/alarm3.png');
}
.bottom.alarm{
  background-color: #d12300;
  background-image: url('../assets/images/alarm/alarm4.png');
}
.alarmList{
  position: absolute;
  bottom: 50px;
  right: 40px;
  /* border: 1px solid #fff !important; */
  background: #041C4C;
  padding: 20px;
  height: 2.5rem;
  width: 4rem;
  z-index: 99999;
  padding-top: 30px;
  transition: 0 10s ease-out;
}
.alarmList2{
  position: absolute;
  bottom: 50px;
  right: 40px;
  /* border: 1px solid #fff !important; */
  background: #d12300;
  padding: 20px;
  /* height: 1.5rem; */
  width: 4rem;
  z-index: 99999;
  padding-top: 10px;
  transition: 0 10s ease-out;
}
.alarmItem{
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #ffffff;
  padding: 10px ;
  border-radius: 5px;
  margin-bottom: 10px;
  width: 3.85rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
}
.alarmItem2{
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #ffffff;
  padding: 10px ;
  border-radius: 5px;
  margin-bottom: 10px;
  width: 3.85rem;
  /* height:100%; */
  /* white-space: nowrap; */
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
}
.alarm3{
  background: #d12300;
}
.alarm2{
  background: #eb6100;
}
.alarm1{
  background: #1773c6;
}
.alarm0{
  background: #17A1C6;
}
/* .el-badge >>> .is-fixed{
  position: absolute;
  right: -25px !important;
  top: -10px !important;
} */
.dialogList{
  position: absolute;
  top: -10px;
  right: -10px;
  font-size: 16px;
  cursor: pointer;
}
.dialogList2{
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 16px;
  cursor: pointer;
}
</style>
