import { getRequest, postRequest, urlTitle ,monitorGateway} from "@/utils/request";
import qs from "qs";
window.qs = qs;
// 告警列表
export const alarmApi = {
  // 告警列表
  queryAlarmList: (params) => {
    return getRequest(`${urlTitle}/alarm/message`,params);
  },
  //已读告警
  alarmRead:(params)=>{
    return postRequest(`${urlTitle}/alarm/${params}/1`)
  },
   //
   queryAlarmStatistic:(params)=>{
    return getRequest(`${monitorGateway}/incident/alarm/id`,params)
  },
  //列表-饼图
  getAnalysisList:(params)=>{
    return getRequest(`${monitorGateway}/incident/station/id/alarm/list`,params)
},
//平台月报警统计
getBarList:(params)=>{
  return getRequest(`${monitorGateway}/incident/station/id/alarm/month/list`,params)
},
//视频告警
videosMedia:(params)=>{
  return getRequest(`${urlTitle}/media/videos`,params)
},
searchDictCodeList:(query)=>{
  return getRequest(`${urlTitle}/asset/info/getDictNameAndDictValue`,query)
},
//查询采集设备列表
collectionList:(params)=>{
  return getRequest(`${monitorGateway}/environment/video`,params)
},
//采集设备列表新增
addCollection:(params)=>{
  return postRequest(`${monitorGateway}/environment/save`,params)
},
//获取采集设备列表详情
getCollDetails:(params)=>{
  return getRequest(`${monitorGateway}/environment/video/${params}`)
},
//采集设备列表修改
editCollection:(params)=>{
  return postRequest(`${monitorGateway}/environment/update`,params)
},
//删除采集设备
deleteCollection:(params)=>{
  return postRequest(`${monitorGateway}/environment/delete?id=${params}`,)
},
//主页右下角告警列表
AlarmList:(params)=>{
  return getRequest(`${urlTitle}/alarm/getAlarmMessagesTotal`,params)
},
//消警确认
updAlaMesEliminateAlarmFlag:(params)=>{
  return postRequest(`${urlTitle}/alarm/updAlaMesEliminateAlarmFlag`,params)
},
//右下角告警弹窗
AlertAlarm:(params)=>{
  return getRequest(`${urlTitle}/alarm/getAlarmMessagesNew`,params)
},
};
