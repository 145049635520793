import {getRequest,postRequest,getDownLoad,urlTitle} from "@/utils/request";
import { post } from "jquery";
// import { post } from "jquery";
import qs from 'qs'
window.qs = qs;



//底部公告栏列表
export const board=() => {
  return getRequest(`${urlTitle}/board/get`);
}
//公告管理列表
export const boardList=(query) => {
  return getRequest(`${urlTitle}/board/list`,query);
}

//新增公告
export const boardAdd=(params) => {
  return postRequest(`${urlTitle}/board/add`,params);
}
//修改公告
export const boardUpdate=(params) => {
  return postRequest(`${urlTitle}/board/update`,params);
}
//删除
export const boardDelete=(params) => {
  return postRequest(`${urlTitle}/board/delete/${params}`);
}





  // 字典查询
  export const operation=(query) => {
      return getRequest(`${urlTitle}/dict/list`, query);
  }
  //根据parentCode查询
  export const dictParentCode=(query) => {
      return getRequest(`${urlTitle}/dict/get/parentCode`,query );
  }
  //新增字典
  export const operationAdd=(query) => {
      return postRequest(`${urlTitle}/dict/add`, query);
  }
  //根据id查询
  export const operationId=(query) => {
    return getRequest(`${urlTitle}/dict/get/${query}`, );
  }
  //修改字典
  export const operationUpdate=(query) => {
    return postRequest(`${urlTitle}/dict/update`,query );
  }
  //字典删除
  export const operationDelete=(query) => {
    return postRequest(`${urlTitle}/dict/${query}` );
  }

  //文档管理
//左侧tree搜索
  export const workType=() => {
    return getRequest(`${urlTitle}/worktype/list?type=1`);
  }
//知识库左侧tree搜索
  export const libraryWorkType=() => {
    return getRequest(`${urlTitle}/worktype/list?type=2`);
  }
// 新建类型
export const workTypeAdd=(query) => {
  return postRequest(`${urlTitle}/worktype/add`,query );
}
//修改类型
export const workTypeUpdate=(query)=>{
  return postRequest(`${urlTitle}/worktype/update`,query)
}
//删除类型
export const workTypeDelete=(query)=>{
  return postRequest(`${urlTitle}/worktype/delete/${query}?type=1`,)
}
//知识库删除类型
export const libraryWorkTypeDelete=(query)=>{
  return postRequest(`${urlTitle}/worktype/delete/${query}?type=2`,)
}
//查询文件列表
export const sysFileList=(query) => {
  return getRequest(`${urlTitle}/sysfile/list`, query);
}
//删除文件
export const sysFileDelete=(query)=>{
  return postRequest(`${urlTitle}/sysfile/delete/${query}`,)
}

//上传文件
export const sysFileUpload=(query)=>{
  return postRequest(`${urlTitle}/sysfile/upload`,query)
}
//下载文件
export const sysFileDownLoad=(query) => {
  return getDownLoad(`${urlTitle}/sysfile/download`, query);
}

//分页查询意见反馈
export const feedBookList=(query)=>{
  return getRequest(`${urlTitle}/feedback/list`,query)
}

///新增/修改 反馈意见
export const feedBookAdd=(query)=>{
  return postRequest(`${urlTitle}/feedback/add`,query)
}
//shanchu 
export const feedBookDelete=(query)=>{
  return postRequest(`${urlTitle}/feedback/delete/${query}`)
}


/*
*  通讯录
*/ 
  //查询list
export const getBookList =query=>{
  return getRequest(`${urlTitle}/address/book/page/list`,query)
}
// 新增
export const addBook=query =>{
  return postRequest(`${urlTitle}/address/book/add`,query)
}
//修改
export const updateBook =query =>{
  return postRequest(`${urlTitle}/address/book/update`,query)
}
//删除 
export const deleteBook=query =>{
  return postRequest(`${urlTitle}/address/book/delete/${query}`)
}

export function checkIfStringInArray(str, arr) {
  return arr.includes(str);
}