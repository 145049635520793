<template>
  <div id="panel-menu">
    <div v-for="first in menu" :key="first.menuName ">
      <div
        class="title"
        
      >
        <div class="title-content">
          <!-- <img v-if="first.icon" :src="first.icon" alt="" /> -->
          <div>{{ first.menuName  }}</div>
        </div>
      </div>
      <!-- 二级菜单 -->
      <ul
        v-show="
          first.children &&
          first.children.length > 0
        "
      >
        <li v-for="second in first.children" :key="second.menuName ">
          <div
            class="child-name"
            :class="selectIndex === second.menuName  ? 'select' : ''"
            @click="clickSecondMenu(second)"
          >
            <span
              :class="selectIndex === second.menuName  ? 'select-text' : ''"
              >{{ second.menuName  }}</span
            >
          </div>
          <div v-show="selectIndex == second.menuName  && secondShow">
            <div
              v-for="third in second.children"
              :key="third.menuName "
              class="three-title"
              @click="clickThirdMenu(third)"
            >
              <div
                class="circle"
                :class="threeChildren === third.menuName  ? 'select-circle' : ''"
              ></div>
              <p :class="threeChildren === third.menuName  ? 'select-text' : ''">
                {{ third.menuName  }}
              </p>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import eventBus from "../utils/bus";
export default {
  name: "PanelMenu",

  data() {
    return {
      menuList: [
        // 安全用电
        // {
        //   name: "安全用电",
        //   path: "safety",
        //   img: require("../assets/images/asideBar/safety.png"),
        //   children: [
        //     {
        //       name: "安防联控",
        //       path: "/safety/light",
        //       children: [
        //         {
        //           name: "灯光联动装置",
        //           path: "/safety/light",
        //         },
        //         {
        //           name: "空调联动装置",
        //           path: "/safety/air",
        //         },
        //         {
        //           name: "红外探测器",
        //           path: "/safety/detector",
        //         },
        //         {
        //           name: "智能门锁",
        //           path: "/safety/lock",
        //         },
        //         {
        //           name: "声光报警器",
        //           path: "/safety/audible",
        //         },
        //       ],
        //     },
        //     {
        //       name: "用电安全",
        //       path: "/safety/temperatureSensor",
        //       children: [
        //         {
        //           name: "无源无线温度传感器",
        //           path: "/safety/temperatureSensor",
        //         },
        //         {
        //           name: "噪声传感器",
        //           path: "/safety/noiseSensor",
        //         },
        //         {
        //           name: "变压器温控",
        //           path: "/safety/transformerControl",
        //         },
        //         {
        //           name: "特高频局放",
        //           path: "/safety/partial",
        //         },
        //         {
        //           name: "变压器声纹",
        //           path: "/safety/vocal",
        //         },
        //       ],
        //     },
        //     {
        //       name: "消防安全",
        //       path: "/safety/smoke",
        //       children: [
        //         {
        //           name: "烟雾传感器",
        //           path: "/safety/smoke",
        //         },
        //         {
        //           name: "温湿度传感器",
        //           path: "/safety/humiditySensor",
        //         },
        //         {
        //           name: "SF6+O3传感器",
        //           path: "/safety/SF6Sensor",
        //         },
        //         {
        //           name: "水浸",
        //           path: "/safety/water",
        //         },
        //       ],
        //     },
        //   ],
        // },
        // // 事件记录
        // {
        //   name: "事件记录",
        //   path: "event",
        //   img: require("../assets/images/asideBar/event.png"),
        //   children: [
        //     {
        //       name: "操作日志",
        //       path: "/event/operation",
        //     },
        //     {
        //       name: "平台运行日志",
        //       path: "/event/platform",
        //     },
        //     {
        //       name: "遥信事件",
        //       path: "/event/remote",
        //     },
        //     {
        //       name: "告警分析",
        //       path: "/event/alarm",
        //     },
        //     {
        //       name: "短信日志",
        //       path: "/event/sms",
        //     },
        //     {
        //       name: "越限事件",
        //       path: "/event/voltage",
        //       children: [
        //         {
        //           name: "电压",
        //           path: "/event/voltage",
        //         },
        //         {
        //           name: "电流",
        //           path: "/event/current",
        //         },
        //         {
        //           name: "视在功率",
        //           path: "/event/apparent",
        //         },
        //         {
        //           name: "功率因数",
        //           path: "/event/factor",
        //         },
        //         {
        //           name: "变压器温度",
        //           path: "/event/heat",
        //         },
        //         {
        //           name: "变压器噪声",
        //           path: "/event/noise",
        //         },
        //         {
        //           name: "变压器负载",
        //           path: "/event/transformerLoad",
        //         },
        //         {
        //           name: "电压频率",
        //           path: "/event/frequency",
        //         },
        //         {
        //           name: "环境温度",
        //           path: "/event/temperature",
        //         },
        //         {
        //           name: "环境湿度",
        //           path: "/event/humidity",
        //         },
        //         {
        //           name: "局放",
        //           path: "/event/discharge",
        //         },
        //         {
        //           name: "高压母线",
        //           path: "/event/highBusbar",
        //         },
        //         {
        //           name: "低压母线",
        //           path: "/event/lowBusbar",
        //         },
        //       ],
        //     },
        //   ],
        // },
        // // 用电分析
        // {
        //   name: "用电分析",
        //   path: "energy",
        //   img: require("../assets/images/asideBar/analysis.png"),
        //   children: [
        //     {
        //       name: "用能报表",
        //       path: "/analysis/energy",
        //     },
        //     {
        //       name: "同比分析",
        //       path: "/analysis/year",
        //     },
        //     {
        //       name: "环比分析",
        //       path: "/analysis/month",
        //     },
        //     {
        //       name: "电能集抄",
        //       path: "/analysis/collect",
        //     },
        //     {
        //       name: "分时段用电",
        //       path: "/analysis/period",
        //     },
        //     {
        //       name: "最大需量报表",
        //       path: "/analysis/demand",
        //     },
        //     {
        //       name: "用电负荷",
        //       path: "/analysis/load",
        //     },
        //     {
        //       name: "实时需量",
        //       path: "/analysis/now",
        //     },
        //     {
        //       name: "用能及电费报表",
        //       path: "/analysis/cost",
        //     },
        //     {
        //       name: "整点集抄",
        //       path: "/analysis/integral",
        //     },
        //   ],
        // },
        // // 电力监测
        // {
        //   name: "电力监测",
        //   path: "monitor",
        //   img: require("../assets/images/asideBar/monitor.png"),
        //   children: [
        //     {
        //       name: "配电图",
        //       path: "/monitor/distribution",
        //     },
        //     {
        //       name: "高压侧",
        //       path: "/monitor/main",
        //       children: [
        //         {
        //           name: "主进线",
        //           path: "/monitor/main",
        //         },
        //         {
        //           name: "馈线",
        //           path: "/monitor/feeder",
        //         },
        //       ],
        //     },
        //     {
        //       name: "低压侧",
        //       path: "/monitor/low",
        //       children: [
        //         {
        //           name: "电力极值报表",
        //           path: "/monitor/extreme",
        //         },
        //         {
        //           name: "电力运行日报",
        //           path: "/monitor/running",
        //         },
        //         {
        //           name: "平均功率因数",
        //           path: "/monitor/power",
        //         },
        //       ],
        //     },
        //     {
        //       name: "变压器",
        //       path: "/monitor/transformer",
        //     },
        //     {
        //       name: "直流屏",
        //       path: "/monitor/screen",
        //     },
        //   ],
        // },
        // // 电能质量
        // {
        //   name: "电能质量",
        //   path: "quality",
        //   img: require("../assets/images/asideBar/quality.png"),
        //   children: [
        //     {
        //       name: "谐波监测",
        //       path: "/quality/wave",
        //     },
        //   ],
        // },
        // // 首页
        // // {
        // //   name: "首页",
        // //   path: "cockpit",
        // // },
        // // 运行环境
        // {
        //   name: "运行环境",
        //   path: "run",
        //   img: require("../assets/images/asideBar/environment.png"),
        //   children: [
        //     {
        //       name: "环境监测",
        //       path: "/run/environment",
        //     },
        //     {
        //       name: "视频监控",
        //       path: "/run/nowMonitor",
        //       children: [
        //         {
        //           name: "实时监控",
        //           path: "/run/nowMonitor",
        //         },
        //         {
        //           name: "监控回放",
        //           path: "/run/playback",
        //         },
        //       ],
        //     },
        //     {
        //       name: "视频设备状态",
        //       path: "/run/videoDevice",
        //     },
        //     {
        //       name: "仪表通讯状态",
        //       path: "/run/instrument",
        //     },
        //     {
        //       name: "网关通信状态",
        //       path: "/run/gateway",
        //     },
        //   ],
        // },
        // {
        //   name: "碳排分析",
        //   path: "RealTime",
        //   img: require("../assets/images/asideBar/knowledge.png"),
        //   children: [
        //     {
        //       name: "实时碳排",
        //       path: "/emissionsCarbon/realTime",
        //     },
        //     {
        //       name: "碳排统计",
        //       path: "/emissionsCarbon/statistics",
        //     },
        //     {
        //       name: "累计碳排",
        //       path: "/emissionsCarbon/cumulative",
        //     },
        //     {
        //       name: "同比分析",
        //       path: "/emissionsCarbon/yearOnYear",
        //     },
        //     {
        //       name: "环比分析",
        //       path: "/emissionsCarbon/monthOnMonth",
        //     },
        //   ],
        // },
      ],
      menu: [],
      hideList: "",
      selectTitle: "",
      selectIndex: "",
      threeChildren: "",
      screen: "",
      addressClick: "",
      secondShow: true,
      thirdShow: false,
      oldName: "",
    };
  },
  created() {},
  mounted() {
    this.hideList = sessionStorage.getItem('hideList')
    this.selectIndex = this.$store.state.selectIndex;
    this.threeChildren = this.$store.state.threeChildren;
    this.$store.state.menuList.forEach((item) => {
      if (item.menuName === "驾驶舱-网") {
        item.children.filter((element) => {
          if (element.menuName  !== "首页" && element.menuName == this.hideList) {
            // element.meta.iconExtPath = require(`../assets/images/asideBar/${element.meta.iconExtPath}.png`);
            this.menuList.push(element);
            this.menu.push(element)
          }
        });
      }
    });
    eventBus.$on("selectedMenu", (data) => {
      this.menu = this.menuList.filter((item) => {
        return item.menuName  === data.name;
      });
    });
    // if (!this.screen) {
    //   this.screen = this.$store.state.hideList;
    //   this.menu = this.menuList.filter((item) => {
    //     debugger
    //     return item.menuName  === this.screen;
    //   });
    // }
  },

  methods: {
    // //是否展示子级
    // openList(index) {
    //   this.hideList = index.name;
    //   this.$store.commit("increment", index.name);
    //   if (index.children) {
    //     let address = index.children[0];
    //     this.selectIndex = address.name;
    //     console.log(this.selectIndex);
    //     this.$store.commit("selectChild", address.name);
    //     if (address.children) {
    //       this.threeChildren = address.children[0].name;
    //       this.$store.commit("selectChild", address.children[0].name);
    //     }
    //   }
    //   this.$router.push({
    //     name: index.url,
    //   });
    // },
    // //选中子级
    // selectChildren(index) {
    //   this.selectIndex = index.name;
    //   if (!index.children) {
    //     this.$store.commit("selectChild", index.name);
    //   } else {
    //     let address = index.children[0];
    //     this.threeChildren = address.name;
    //     this.$store.commit("selectChild", index.name);
    //   }
    //   this.$router.push(index.url);
    // },
    // clickChildren(el) {
    //   this.threeChildren = el.name;
    //   this.$router.push(el.url);
    // },
    //是否展示子级
    clickFirstMenu(index) {
      this.hideList = index.menuName ;
      this.secondShow = true;
      this.$store.commit("increment", index.menuName );
      if (index.children.length !== 0) {
        let address = index.children[0];
        this.selectIndex = address.menuName ;
        this.$store.commit("selectChild", address.menuName );
        if (address.children.length !== 0) {
          if (this.threeChildren == "") {
            this.threeChildren = address.children[0].menuName ;
            this.addressClick = address.children[0].url;
            this.$store.commit("selectChildren", address.children[0].menuName );
            this.$router.push(this.addressClick);
          } else {
            if (!this.addressClick) {
              this.threeChildren = address.children[0].menuName ;
              this.addressClick = address.children[0].url;
              this.$store.commit(
                "selectChildren",
                address.children[0].menuName 
              );
            } else {
              if (this.oldName !== index.menuName ) {
                this.threeChildren = address.children[0].menuName ;
                this.addressClick = address.children[0].url;
                this.$store.commit(
                  "selectChildren",
                  address.children[0].menuName 
                );
              }
            }
            this.oldName = index.menuName ;

            this.$router.push(this.addressClick);
          }
        } else {
          this.$router.push(address.url);
        }
      } else {
        this.$router.push({
          name: index.url,
        });
      }
    },
    //点击二级菜单
    clickSecondMenu(index) {
      if(this.secondShow){
        this.secondShow = true;
      }else{
        this.secondShow = !this.secondShow;
      }
      this.selectIndex = index.menuName ;
      if (!(index.children&&index.children.length>0)) {
        this.$store.commit("selectChild", index.menuName );
      } else {
        let address = index.children[0];
        this.threeChildren = address.menuName ;
        this.addressClick = index.url;
        this.$store.commit("selectChild", index.menuName );
      }
      this.$router.push(index.url);
    },
    clickThirdMenu(el) {
      this.$store.commit("selectChildren", el.menuName );
      this.threeChildren = el.menuName ;
      this.addressClick = el.url;
      this.$router.push(el.url);
    },
  },
};
</script>

<style scoped>
#panel-menu {
  font-size: 16px;
}

.title {
  width: 100%;
  height: 55px;
  padding-left: 18px;
  box-sizing: border-box;
  cursor: pointer;
  background: url("../assets/images/asideBar/menu-background.png") no-repeat
    center;
  background-size: cover;
  box-sizing: border-box;
}

.title-content {
  height: 55px;
  display: flex;
  align-items: center;
}

.title-content img {
  width: 24px;
  height: 24px;
  margin-right: 4px;
}
.title-content div {
  color: #ffffff;
  font-weight: 700;
  font-size: 16px;
}

ul {
  background-color: rgba(48, 149, 255, 0.06);
  margin: 0;
  padding: 0;
  /* list-style: none; */
}
ul > li:hover {
  color: rgba(255, 199, 74, 0.88);
}
.child-name {
  width: 100%;
  height: 48px;
  background: url("../assets/images/asideBar/default-background.png") no-repeat
    center;
  background-size: cover;
  color: #aed6ff;
  font-size: 14px;
  line-height: 48px;
  padding-left: 48px;
  box-sizing: border-box;
  cursor: pointer;
}
.select {
  background: url("../assets/images/asideBar/select-background.png") no-repeat
    center !important;
}
.circle {
  width: 3px;
  height: 3px;
  border-radius: 50%;
  background-color: #99c0eb;
}
.three-title {
  display: flex;
  align-items: center;
  padding-left: 40px;
}
.three-title p {
  height: 33px;
  margin-left: 8px;
  font-size: 12px;
  color: #aed6ff;
  line-height: 33px;
  cursor: pointer;
}

.three-title p:hover {
  color: rgba(255, 199, 74, 0.88);
  pointer-events: none;
}
.select-text {
  color: #ffc74a !important;
}
.select-circle {
  background-color: #ffc74a !important;
}
</style>
