<template>
  <div id="nav">
    <div class="flex-horizontal-between">
      <div class="left-position">
        <ul class="nav-left">
          <div class="" v-for="item in leftNav"
              :key="item.name"
              :class="select === item.name ? 'left-select' : 'left-default'"
              @click.stop="switchNav(item)">

            <li v-if="showCityHome(item.path)">
              <img :src="item.icon" alt="" />
              <span>{{ item.name }}</span>
            </li>
          </div>
        </ul>
      </div>
      <div class="right-position">
        <ul class="nav-right">
          <div class=""  v-for="item in rightNav"
              :key="item.name"
              :class="select === item.name ? 'right-select' : 'right-default'"
              @click.stop="switchNav(item)">

            <li v-if="showCityHome(item.path)">
              <img :src="item.icon" alt="" />
              <span>{{ item.name }}</span>
            </li>
          </div>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
import eventBus from "../utils/bus";

export default {
  data() {
    return {
      leftNav: [
        {
          name: "首页",
          icon: require("../assets/images/cockpit/home-page.png"),
          path: "/cockpit/electricity",
        },
        {
          name: "用电分析",
          icon: require("../assets/images/cockpit/electric-analyse.png"),
          path: "/analysis/energy",
          searchName: "用能报表",
        },
        {
          name: "电力监测",
          icon: require("../assets/images/cockpit/monitor.png"),
          path: "/monitor/distribution",
          searchName: "配电图",
        },
        {
          name: "电能质量",
          icon: require("../assets/images/cockpit/quality.png"),
          path: "/quality/wave",
          searchName: "谐波监测",
        },
      ],
      rightNav: [
        {
          name: "事件记录",
          icon: require("../assets/images/cockpit/record.png"),
          path: "/event/alarm",
          searchName: "告警列表",
        },
        {
          name: "运行环境",
          icon: require("../assets/images/cockpit/quality.png"),
          path: "/run/environment",
          searchName: "环境监控",
        },
        {
          name: "安全用电",
          icon: require("../assets/images/cockpit/monitor.png"),
          path: "/safety/securityControl",
          searchName: "安防联控",
        },
        // {
        //   name: "碳排分析",
        //   icon: require("../assets/images/cockpit/carbon-analyse.png"),
        //   path: "/emissionsCarbon/realTime",
        //   searchName: "实时碳排",
        // },
      ],
      select: "",
      electricityHome:[],
      pathCityHome:[],
    };
  },
  methods: {
    switchNav(item) {
      this.select = item.name;
      if (item.name !== "首页" && item.name !== '碳排分析') {
        this.$store.commit("increment", item.name);
        sessionStorage.setItem('hideList',item.name)
        this.$store.commit("selectChild", item.searchName);
      }
      setTimeout(() => {
        eventBus.$emit("selectedMenu", { ...item});
      }, 500);
      this.$router.push({
        path: item.path,
      });
    },
    authorityList(){

    this.electricityHome=JSON.parse(sessionStorage.getItem('electricityHome')).children
      console.log(this.electricityHome,'3333')
    if(this.electricityHome&&this.electricityHome.length > 0){
      console.log(this.electricityHome.children);
      this.electricityHome.forEach(item=>{
        this.pathCityHome.push(item.url)
      })
      console.log(this.pathCityHome);
    }
    },
    showCityHome(item){
     return this.pathCityHome.includes(item)
    }

  },
  mounted() {
    if(this.$store.state.hideList==='驾驶舱-网'){
      this.select = "首页";
    }else{
      this.select = this.$store.state.hideList;
    }
    this.authorityList()
  },
};
</script>
<style scoped>
ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.flex-row {
  display: flex;
  flex-direction: row;
}
.flex-horizontal-between {
  display: flex;
  justify-content: center;
}
#nav {
  width: 100%;
  position: absolute;
  top: 66px;
}
.nav-left {
  display: flex;
  /* justify-content: center; */
  /* width: 50%; */
  /* margin-left: 12px; */
}
.left-position {
  position: relative;
  right: 20%;
}
.right-position {
  position: relative;
  left: 14%;
}
.nav-right {
  display: flex;
  /* justify-content: center; */
  /* width: 50%; */
  /* margin-right: 200px; */
}
.nav-left li,
.nav-right li {
  width: 134px;
  height: 35px;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  cursor: pointer;
  margin-right: 4px;
}
ul li span {
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #bccaf3;
}
ul li img {
  width: 15px;
  height: 15px;
}
.nav-left li span {
  position: absolute;
  top: 14px;
  left: 51px;
}
.nav-right li span {
  position: absolute;
  top: 15px;
  left: 54px;
}
.nav-left img {
  position: absolute;
  left: 30px;
  top: 14px;
}
.nav-right img {
  position: absolute;
  top: 15px;
  left: 32px;
}
ul li span {
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #bccaf3;
}
.left-select {
  background-image: url("../assets/images/cockpit/nav-left-select.png");
}
.right-select {
  background-image: url("../assets/images/cockpit/nav-right-select.png");
}
.left-default {
  background-image: url("../assets/images/cockpit/nav-left-default.png");
}
.right-default {
  background-image: url("../assets/images/cockpit/nav-right-default.png");
}
</style>
